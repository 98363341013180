<template>
  <div>
    <floating-button-save
      v-if="!revision"
      :valid="valid"
      :disabled="isDisabled"
      @submit="createAuditoria()"></floating-button-save>
    <loading v-model="$store.getters.loading" text="Espere por favor ..." />
    <loading v-model="ui.loading" text="Espere por favor ..." />
    <v-card v-if="isNew" class="pa-3">
      <h1 class="ma-2">{{ $t('nueva_auditoria') }}</h1>

      <v-form ref="form" lazy-validation v-if="!ui.loading">
        <v-container>
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="cliente.nombreCompleto"
                :label="$t('cliente')"
                outlined
                dense
                readonly
                :rules="rules">
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="item.expediente.codigo"
                :label="$t('expediente')"
                outlined
                dense
                readonly
                :rules="rules"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="item.expediente.subalcance.nombre"
                :label="$t('marca')"
                outlined
                dense
                readonly
                :rules="rules"></v-text-field>
            </v-col>
            <v-col>
              <v-menu v-model="menuFecha1" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    prepend-icon="mdi-calendar"
                    :value="formatDate(item.desde)"
                    :label="$t('inicio')"
                    outlined
                    dense
                    readonly
                    v-on="on"
                    :rules="rules"></v-text-field>
                </template>
                <v-date-picker
                  v-model="item.desde"
                  color="primary"
                  no-title
                  scrollable
                  first-day-of-week="1"
                  @change="menuFecha1 = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu v-model="menuFecha2" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    prepend-icon="mdi-calendar"
                    :value="formatDate(item.hasta)"
                    :label="$t('fin')"
                    outlined
                    dense
                    readonly
                    v-on="on"
                    :rules="rules"></v-text-field>
                </template>
                <v-date-picker
                  v-model="item.hasta"
                  color="primary"
                  no-title
                  scrollable
                  first-day-of-week="1"
                  @change="menuFecha2 = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              <v-checkbox
                dense
                v-model="selectAllAuditores"
                :color="item.subalcance.color"
                :label="$t('todos')" />
            </v-col>

            <v-col cols="7">
              <SelectAuditores
                ref="selectAuditores"
                v-model="item.auditores"
                :title="$t('auditores')"
                required
                dense
                compatibles
                :desde="item.desde"
                :subalcance="item.subalcance ? item.subalcance.uuid : null"
                :cliente="item.cliente.uuid"
                :select_all="selectAllAuditores"
                :restrain="restrainAuditores"
                @clear="selectAllAuditores = false"
                @all="selectAllAuditores = true" />
            </v-col>

            <v-col cols="4">
              <v-autocomplete
                v-model="item.responsable"
                :label="$t('responsable')"
                :hint="$t('haga_clic_en_uno_de_los_auditores_para_marcarlo_como_responsable')"
                :items="item ? item.auditores : []"
                :item-text="nombreCompleto"
                item-value="uuid"
                :disabled="!item.auditores.length"
                :rules="rules"
                dense
                outlined
                required
                small-chips></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-row>
              <v-col
                cols="12"
                class="v-data-table theme--light"
                v-if="item.cargos && Object.keys(this.item.cargos).length > 0">
                <div class="v-data-table__wrapper">
                  <table
                    style="
                      border: 1px solid #ccc;
                      background: #f9f9f9;
                      border-radius: 3px;
                      box-shadow: 0px 0px 3px 0px #e2e2e2;
                    ">
                    <thead>
                      <tr>
                        <th class="text-left">Auditor</th>
                        <th class="text-left">En calidad de</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template>
                        <tr v-for="(aud, key) in this.item.cargos" :key="key">
                          <td>{{ aud.nombre }} {{ aud.apellidos }}</td>
                          <td>
                            <v-col cols="5" class="pl-0">
                              <SelectEstaticos
                                v-model="aud.cargo"
                                :subalcance="item.subalcance"
                                codigo="cargo-de-auditor"
                                required
                                outlined
                                dense
                                :itemValue="'valor'"
                                @change="guardarCambiosAuditoria()"
                                :estaticos="estaticos"
                                :key="component" />
                            </v-col>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </v-col>

              <v-col cols="12" class="v-data-table theme--light pa-1">
                <div class="v-data-table__wrapper">
                  <table
                    style="
                      border: 1px solid #ccc;
                      background: #f9f9f9;
                      border-radius: 3px;
                      box-shadow: 0px 0px 3px 0px #e2e2e2;
                    ">
                    <thead>
                      <tr>
                        <th class="text-left">Acompañante</th>
                        <th class="text-left">En calidad de</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="py-5">
                          <v-text-field
                            :label="$t('primer_acompañante')"
                            v-model="item.acompanante_1"
                            outlined
                            hide-details="auto"
                            dense></v-text-field>
                        </td>
                        <td class="py-5">
                          <v-text-field
                            v-model="item.cargo_acompanante_1"
                            outlined
                            hide-details="auto"
                            dense></v-text-field>
                        </td>
                      </tr>

                      <tr>
                        <td class="py-5">
                          <v-text-field
                            :label="$t('segundo_acompañante')"
                            v-model="item.acompanante_2"
                            hide-details="auto"
                            outlined
                            dense></v-text-field>
                        </td>
                        <td class="py-5">
                          <v-text-field
                            v-model="item.cargo_acompanante_2"
                            hide-details="auto"
                            outlined
                            dense></v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col cols="6">
                <v-row>
                  <v-col cols="6">
                    <SelectEstaticos
                      v-if="item && item.subalcance"
                      v-model="item.tipoVisita"
                      :label="$t('tipo_de_auditoría')"
                      :estaticos="estaticos"
                      codigo="tipos-de-auditoria"
                      :subalcance="item.subalcance"
                      :disabled="!item.subalcance"
                      multiple
                      required
                      outlined
                      dense />
                  </v-col>
                  <v-col cols="6">
                    <SelectEstaticos
                      v-if="item && item.subalcance"
                      v-model="item.modoVisita"
                      :label="$t('modo_de_auditoría')"
                      :estaticos="estaticos"
                      codigo="modos-de-auditoria"
                      :subalcance="item.subalcance"
                      :disabled="!item.subalcance"
                      required
                      outlined
                      dense />
                  </v-col>

                  <v-col cols="6">
                    <SelectEstaticos
                      v-if="item.subalcance"
                      dense
                      v-model="item.nivel"
                      :label="$t('nivel')"
                      codigo="nivel-de-control"
                      required
                      outlined
                      :estaticos="estaticos" />
                  </v-col>

                  <v-col cols="6">
                    <v-select
                      dense
                      :rules="[(v) => !!v || 'Obligatorio']"
                      :items="[
                        {
                          value: 'PREVISTA',
                          label: 'PREVISTA',
                        },
                        {
                          value: 'CONFIRMADA',
                          label: 'CONFIRMADA',
                        },
                      ]"
                      item-text="label"
                      item-value="value"
                      v-model="item.estado"
                      outlined
                      :label="$t('estado_de_la_auditoria')"
                      nope-change="change_roles = true"></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <v-col cols="4">
                    <v-checkbox
                      v-if="item && item.subalcance"
                      v-model="item.anunciada"
                      :disabled="!item.subalcance"
                      :color="item.subalcance.color"
                      :label="$t('anunciada')"
                      required
                      dense />
                  </v-col>

                  <v-col cols="4">
                    <v-checkbox
                      v-model="item.bloqueada"
                      dense
                      :color="item.subalcance.color"
                      :label="$t('bloqueada')" />
                  </v-col>

                  <v-col cols="4" v-if="!auditoria">
                    <v-checkbox
                      v-model="item.nuevaIteracion"
                      dense
                      :color="item.subalcance.color"
                      :label="$t('crear_en_una_iteración_nueva')" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-col cols="12">
              <v-textarea
                outlined
                :hint="$t('notas_de_la_planificacion')"
                persistent-hint
                v-model="item.notas_planificacion" />
            </v-col>
            <v-col cols="12">
              <SelectEmplazamientoProductos
                v-if="item"
                :emplazamientos="item.emplazamientos"
                :productos="item.productos"
                :subalcance="item.subalcance"
                @updateSelectedEmplazamientos="updateSelectedEmplazamientos($event)"
                @updateSelectedProductos="updateSelectedProductos($event)" />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>

    <v-card v-else class="pa-3">
      <!-- <h1 class="ma-2">{{ $t('auditoria') }}</h1> -->

      <v-form ref="form" lazy-validation v-model="valid" style="height: 100%">
        <v-container>
          <v-row class="ml-1 mr-1" v-if="item">
            <v-col cols="12">
              <v-tabs v-model="tab" @change="setTabUrl()" :color="currentColorTab">
                <v-tab key="general">{{ $t('general') }}</v-tab>
                <v-tab key="checklists">Checklists</v-tab>
                <v-tab key="pacs">PACS</v-tab>
                <v-tab key="muestras">Muestras</v-tab>
                <v-tab key="bloques">Bloques</v-tab>

                <v-spacer />

                <!-- ESTAS OPERACIONES SOLO LAS PUEDE HACER EL RESPONSABLE DE LA AUDITORÍA -->
                <div v-if="!isCliente">
                  <!-- EXPEDIENTE -->
                  <v-btn
                    class="mt-2 mr-4"
                    color="grey"
                    small
                    outlined
                    depressed
                    @click="gotoExpediente()">
                    Ir al expediente
                  </v-btn>

                  <!-- MODO OFFLINE -->
                  <!-- <v-btn
                    v-if="item && auditoriaAbiertaPrevista && !consulta && !offlineMode"
                    class="mt-2 mr-4 white--text"
                    color="grey"
                    small
                    depressed
                    @click="confirmarModoOffline()"
                    >
                      Modo Offline
                    </v-btn> -->
                  <template v-if="item && canSaveEstadoPreliminar">
                    <v-btn
                      v-if="!item.estadoPreliminar"
                      class="mt-2 mr-4 white--text"
                      color="teal"
                      small
                      depressed
                      @click="ui.showPreliminarState = true">
                      Guardar estado preliminar
                    </v-btn>
                    <v-btn
                      v-if="item.estadoPreliminar"
                      class="mt-2 mr-4 white--text"
                      color="teal"
                      small
                      depressed
                      @click="ui.showPreliminarState = true">
                      Actualizar estado preliminar
                    </v-btn>
                  </template>

                  <!-- valorar auditoria -->

                  <template v-if="item && !item.valoracion">
                    <v-btn
                      v-if="tab == 0 && !auditoriaCerrada"
                      class="mt-2 mr-4 white--text"
                      color="purple"
                      small
                      depressed
                      @click="ui.showValoration = true">
                      Valorar
                    </v-btn>
                  </template>

                  <!-- Cerrar -->
                  <v-btn
                    v-if="
                      auth.canClose &&
                      esResponsable &&
                      !consulta &&
                      item &&
                      auditoriaAbiertaPrevista
                    "
                    :disabled="offlineMode"
                    class="mt-2 mr-4 white--text"
                    color="error"
                    small
                    depressed
                    @click="confirmarCambioEstado(estado.CERRADA)">
                    Cerrar auditoría
                  </v-btn>

                  <!-- Abrir -->
                  <v-btn
                    v-if="auth.canOpen && esResponsable && !consulta && item && auditoriaCerrada"
                    :disabled="offlineMode"
                    class="mt-2 mr-4 white--text"
                    color="success"
                    small
                    depressed
                    @click="confirmarCambioEstado(estado.ABIERTA)">
                    Abrir auditoría
                  </v-btn>

                  <!-- REVISIÓN -->
                  <!-- <div v-if="esResponsable && revision">
                        <v-btn @click="dialogIntroducirMotivo = true" small color="error" class="estado-auditoria-left">Revisión KO</v-btn>
                        <v-btn @click="confirmarCambioEstado(estado.REVISADA_OK)" small color="green" dark class="estado-auditoria">Revisión OK</v-btn>
                      </div> -->

                  <!-- DICTAMEN -->
                  <div v-if="esResponsable && dictamen">
                    <v-btn
                      @click="dialogIntroducirMotivo = true"
                      small
                      color="error"
                      class="estado-auditoria-left"
                      >NO APTA
                    </v-btn>
                    <v-btn
                      @click="confirmarCambioEstado(estado.APTA)"
                      small
                      color="green"
                      dark
                      class="estado-auditoria">
                      APTA
                    </v-btn>
                  </div>
                </div>

                <!-- TAB GENERAL -->
                <v-tab-item
                  :transition="false"
                  :reverse-transition="false"
                  key="general"
                  :class="{ isLocked: isLocked }">
                  <v-row v-if="ui.showValoration">
                    <v-col cols="6" class="mx-auto pa-8 text-center">
                      <v-icon style="font-size: 80px; color: #d2d5db" class="my-4">
                        mdi-message-check-outline
                      </v-icon>
                      <h2>Valorar auditoría</h2>

                      <p>
                        Antes de cerrar la auditoría, es necesario valorarla <br />Por favor,
                        selecciona la valoración que corresponda
                      </p>

                      <template v-if="!ui.loading">
                        <v-btn
                          :outlined="!item.valoracion || item.valoracion !== 'Satisfactoria'"
                          color="success darken-2"
                          depressed
                          @click="
                            item.valoracion = 'Satisfactoria'
                            guardarCambiosAuditoria()
                          ">
                          <!-- Satisfactoria -->
                          {{ ui.text._('auditorias.valorar.satisfactoria') }}
                        </v-btn>
                        <span class="mx-2">&nbsp;</span>
                        <v-btn
                          :outlined="!item.valoracion || item.valoracion !== 'No satisfactoria'"
                          color="error darken-2"
                          depressed
                          @click="
                            item.valoracion = 'No satisfactoria'
                            guardarCambiosAuditoria()
                          ">
                          <!-- No Satisfactoria -->
                          {{ ui.text._('auditorias.valorar.no_satisfactoria') }}
                        </v-btn>
                        <div class="text-caption grey--text py-3" v-if="item.valoracion">
                          La auditoría se ha valorado como {{ item.valoracion }}
                        </div>
                      </template>
                      <v-progress-linear
                        v-else
                        color="#d22bc5"
                        indeterminate
                        rounded
                        height="6"></v-progress-linear>
                    </v-col>
                  </v-row>

                  <!-- DATOS GENERALES -->

                  <v-card class="mt-5" flat v-if="item">
                    <v-card-text>
                      <v-row>
                        <v-col cols="4">
                          <v-text-field
                            :label="$t('código')"
                            v-model="item.codigo"
                            outlined
                            dense
                            @change="guardarCambiosAuditoria()"
                            :disabled="isDisabled"></v-text-field>
                        </v-col>

                        <v-col cols="2">
                          <v-autocomplete
                            v-model="item.emitidoPor"
                            :label="$t('emitido_por')"
                            :hint="
                              $t('haga_clic_en_uno_de_los_auditores_para_marcarlo_como_emisor')
                            "
                            :items="item ? item.auditores : []"
                            :item-text="nombreCompleto"
                            item-value="@id"
                            :disabled="!item.auditores.length || isDisabled"
                            :rules="rules"
                            dense
                            outlined
                            required
                            small-chips></v-autocomplete>
                        </v-col>

                        <v-col cols="4">
                          <v-btn
                            text
                            block
                            outlined
                            color="primary lighten-2"
                            prepend-icon="mdi-balot-outlined"
                            :disabled="isDisabled"
                            @click="
                              getPlanes()
                              ui.showPlanes = !ui.showPlanes
                            ">
                            plan de auditoría
                          </v-btn>
                        </v-col>

                        <v-col cols="2" style="float: right">
                          <v-chip
                            :color="
                              item.estado === 'Abierta'
                                ? 'success'
                                : item.estado === 'Cerrada'
                                ? 'error'
                                : 'info'
                            "
                            class="white--text ml-2"
                            small
                            :disabled="isDisabled"
                            style="float: right">
                            {{ item.estado }}
                          </v-chip>
                        </v-col>
                        <v-col v-if="auditoriaCerrada">
                          <v-text-field
                            prepend-icon="mdi-calendar"
                            :label="$t('fecha_cierre')"
                            outlined
                            dense
                            :value="formatDate(item.fecha_cierre)"
                            disabled></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="ui.showPlanes"
                        class="mx-3 my-2"
                        style="padding: 6px; background: #f0f0f0">
                        <v-col cols="12">
                          <v-card>
                            <v-card-title>
                              <h3>Planes de auditoría</h3>
                            </v-card-title>
                            <v-card-text>
                              <v-row>
                                <v-col cols="6">
                                  <v-autocomplete
                                    v-model="plan"
                                    :items="planes_auditoria"
                                    item-text="texto"
                                    item-value="uuid"
                                    :label="$t('descripciones')"
                                    required
                                    dense
                                    clearable
                                    @change="addPlan"></v-autocomplete>
                                </v-col>
                                <v-col cols="6">
                                  <v-autocomplete
                                    v-model="plan_grupo"
                                    :items="grupos_planes_auditoria"
                                    item-text="nombre"
                                    item-value="uuid"
                                    :label="$t('grupos_de_descripciones')"
                                    required
                                    dense
                                    clearable
                                    @change="addPlanGrupo"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" v-if="item && item.plan_de_auditoria">
                                  <v-data-table
                                    :headers="columnDefs"
                                    :items="item.plan_de_auditoria"
                                    class="elevation-1"
                                    :disabled="isDisabled"
                                    :item-key="item.uuid"
                                    :key="component">
                                    <template v-slot:item.texto="{ item }">
                                      <div class="row">
                                        <div class="col-6">
                                          <v-btn icon x-small @click="moveUp(item)">
                                            <v-icon>mdi-arrow-up</v-icon>
                                          </v-btn>
                                          <v-btn icon x-small @click="moveDown(item)">
                                            <v-icon>mdi-arrow-down</v-icon>
                                          </v-btn>
                                        </div>
                                        <div class="col-6">
                                          <v-edit-dialog
                                            :return-value.sync="item.texto"
                                            @save="save"
                                            @cancel="cancel"
                                            @open="open"
                                            @close="close">
                                            <span>{{ item.texto }}</span>
                                            <template v-slot:input>
                                              <v-text-field
                                                class="pt-4"
                                                v-model="item.texto"
                                                :label="$t('texto')"
                                                required
                                                dense
                                                counter></v-text-field>
                                              <v-chip
                                                class="my-1 mx-1"
                                                color="info"
                                                text-color="white"
                                                label
                                                >presionar enter para guardar</v-chip
                                              >
                                            </template>
                                          </v-edit-dialog>
                                        </div>
                                      </div>
                                    </template>

                                    <template v-slot:item.hora="{ item }">
                                      <v-edit-dialog
                                        :return-value.sync="item.hora"
                                        @save="save"
                                        @cancel="cancel"
                                        @open="open"
                                        @close="close"
                                        @mouseout="save">
                                        <span>{{ item.hora }}</span>
                                        <template v-slot:input>
                                          <v-text-field
                                            class="pt-4"
                                            v-model="item.hora"
                                            :label="$t('hora')"
                                            required
                                            dense
                                            counter></v-text-field>
                                          <v-chip
                                            class="my-1 mx-1"
                                            color="info"
                                            text-color="white"
                                            label
                                            >presionar enter para guardar</v-chip
                                          >
                                        </template>
                                      </v-edit-dialog>
                                    </template>

                                    <template v-slot:item.negrita="{ item }">
                                      <v-switch v-model="item.negrita" color="success"></v-switch>
                                    </template>

                                    <template v-slot:item.fecha="{ item }">
                                      <v-switch v-model="item.fecha" color="success"></v-switch>
                                    </template>

                                    <template v-slot:item.site="{ item }">
                                      <v-edit-dialog
                                        :return-value.sync="item.site"
                                        @save="save"
                                        @cancel="cancel"
                                        @open="open"
                                        @close="close"
                                        @mouseout="save">
                                        <span>{{ item.site }}</span>
                                        <template v-slot:input>
                                          <v-text-field
                                            class="pt-4"
                                            v-model="item.site"
                                            :label="$t('site')"
                                            required
                                            dense
                                            counter></v-text-field>
                                          <v-chip
                                            class="my-1 mx-1"
                                            color="info"
                                            text-color="white"
                                            label
                                            >presionar enter para guardar</v-chip
                                          >
                                        </template>
                                      </v-edit-dialog>
                                    </template>

                                    <template v-slot:item.actions="{ item }">
                                      <v-btn icon color="error" @click="removeRow(item)">
                                        <v-icon>mdi-delete</v-icon>
                                      </v-btn>
                                    </template>
                                  </v-data-table>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          <v-text-field
                            v-if="item && item.expediente"
                            :label="$t('expediente')"
                            outlined
                            dense
                            v-model="item.expediente.codigo"
                            disabled>
                          </v-text-field>
                        </v-col>

                        <v-col cols="4">
                          <v-text-field
                            :label="$t('cliente')"
                            outlined
                            dense
                            v-model="item.cliente.nombreCompleto"
                            disabled>
                            <template v-slot:append>
                              <v-btn icon @click="goToClienteFicha">
                                <v-icon>mdi-account-box</v-icon>
                              </v-btn>
                            </template>
                          </v-text-field>
                        </v-col>

                        <v-col cols="4">
                          <v-text-field
                            v-if="item.subalcance"
                            :label="$t('marca')"
                            outlined
                            dense
                            v-model="item.subalcance.nombre"
                            disabled></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="2">
                          <v-menu
                            v-model="menuFecha1"
                            :close-on-content-click="false"
                            max-width="290">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                nope-prepend-icon="mdi-calendar"
                                :value="formatDate(item.desde)"
                                :label="$t('fecha_de_inicio_(desde)')"
                                outlined
                                dense
                                readonly
                                v-on="on"
                                :disabled="isDisabled"></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="item.desde"
                              color="primary"
                              no-title
                              scrollable
                              first-day-of-week="1"
                              @change="
                                ;(menuFecha1 = false), guardarCambiosAuditoria()
                              "></v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="1">
                          <v-text-field
                            type="time"
                            :label="$t('hora_inicio')"
                            outlined
                            dense
                            :value="horaToTime(item.desde_inicio)"
                            @change="setTime('desde_inicio', $event)"
                            :disabled="isDisabled"></v-text-field>
                          <!-- v-model="item.desdeInicio"
                            @change="item.desdeInicio = item.desdeInicio + ':00'; guardarCambiosAuditoria()" -->
                        </v-col>

                        <v-col cols="1">
                          <v-text-field
                            type="time"
                            :label="$t('hora_fin')"
                            outlined
                            dense
                            :value="horaToTime(item.desdeFin)"
                            @change="setTime('desdeFin', $event)"
                            :disabled="isDisabled"></v-text-field>
                        </v-col>

                        <v-col cols="2">
                          <v-menu
                            v-model="menuFecha2"
                            :close-on-content-click="false"
                            max-width="290">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                nope-prepend-icon="mdi-calendar"
                                :value="formatDate(item.hasta)"
                                :label="$t('fecha_de_fin_(hasta)')"
                                outlined
                                dense
                                readonly
                                v-on="on"
                                :disabled="isDisabled"></v-text-field>
                            </template>
                            <v-date-picker
                              color="primary"
                              v-model="item.hasta"
                              first-day-of-week="1"
                              no-title
                              scrollable
                              @change="
                                ;(menuFecha2 = false), guardarCambiosAuditoria()
                              "></v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="1">
                          <v-text-field
                            type="time"
                            :label="$t('hora_inicio')"
                            outlined
                            dense
                            :value="horaToTime(item.hastaInicio)"
                            @change="setTime('hastaInicio', $event)"
                            :disabled="isDisabled"></v-text-field>
                        </v-col>

                        <v-col cols="1">
                          <v-text-field
                            type="time"
                            :label="$t('hora_fin')"
                            outlined
                            dense
                            :value="horaToTime(item.hastaFin)"
                            @change="setTime('hastaFin', $event)"
                            :disabled="isDisabled"></v-text-field>
                        </v-col>

                        <v-col cols="4">
                          <v-btn
                            text
                            block
                            outlined
                            color="primary lighten-2"
                            @click="ui.showFechas = !ui.showFechas"
                            :disabled="isDisabled">
                            Gestión de fechas
                            <v-icon right dark> mdi-calendar-clock </v-icon>
                          </v-btn>
                        </v-col>

                        <v-row
                          v-if="ui.showFechas"
                          class="mx-2 my-2"
                          style="padding: 6px; background: #f0f0f0">
                          <v-col cols="12">
                            <h4>Gestión granular de horarios</h4>
                          </v-col>

                          <template v-for="(f, i) in item.fechas">
                            <v-row>
                              <v-col cols="3" class="d-flex align-center justify-center mt-0 pb-0">
                                <v-icon class="px-3" style="transform: translate(-7px, -5px)"
                                  >mdi-calendar-outline</v-icon
                                >

                                <v-menu
                                  v-model="f.model"
                                  :close-on-content-click="false"
                                  max-width="290">
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      :value="formatDateGestionFechas(f.desde)"
                                      :label="$t('fecha')"
                                      readonly
                                      v-on="on"
                                      dense
                                      outlined></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="f.desde"
                                    color="primary"
                                    first-day-of-week="1"
                                    no-title
                                    scrollable
                                    @change="
                                      ;(f.model = false), $forceUpdate(), guardarCambiosAuditoria()
                                    "></v-date-picker>
                                </v-menu>
                              </v-col>

                              <v-col cols="3" class="d-flex align-center justify-center mt-0 pb-0">
                                <v-text-field
                                  type="time"
                                  :label="$t('hora_inicio')"
                                  v-model="f.d_inicio"
                                  @change="
                                    f.d_inicio = f.d_inicio + ':00'
                                    guardarCambiosAuditoria()
                                  "
                                  dense
                                  outlined></v-text-field>
                              </v-col>

                              <v-col cols="3" class="d-flex align-center justify-center mt-0 pb-0">
                                <v-text-field
                                  type="time"
                                  :label="$t('hora_fin')"
                                  v-model="f.d_fin"
                                  @change=";(f.d_fin = f.d_fin + ':00'), guardarCambiosAuditoria()"
                                  dense
                                  outlined></v-text-field>
                              </v-col>

                              <v-col cols="3">
                                <SelectEstaticos
                                  v-model="f.modo"
                                  :label="$t('modo_de_auditoría')"
                                  :estaticos="estaticos"
                                  codigo="modos-de-auditoria"
                                  :subalcance="item.subalcance"
                                  dense
                                  outlined />
                              </v-col>

                              <v-col cols="1">
                                <v-btn
                                  text
                                  small
                                  depressed
                                  color="error darken-3"
                                  @click="removeFecha(i)">
                                  <v-icon small class="pr-2">mdi-sort-variant-remove</v-icon>
                                  Eliminar
                                </v-btn>
                              </v-col>
                            </v-row>
                          </template>

                          <v-col cols="12" class="d-flex align-center">
                            <v-btn text small depressed color="success darken-3" @click="addFecha">
                              <v-icon class="pr-2"> mdi-table-row-plus-after </v-icon>
                              Añadir rango
                            </v-btn>

                            <v-btn text small depressed color="dark" @click="ui.showFechas = false">
                              Cerrar
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-row>

                      <v-row>
                        <!-- <v-col cols="12">
                            <v-divider style="max-width: 75%" class="mx-auto my-5"></v-divider>
                          </v-col> -->

                        <v-col cols="4">
                          <SelectAuditores
                            v-if="auditoria && item && item.subalcance"
                            v-model="item.auditores"
                            :title="$t('auditores')"
                            required
                            compatibles
                            :subalcance="item.subalcance.uuid"
                            :cliente="item.cliente.uuid"
                            :disabled="isDisabled || !canEditAuditores"
                            @change="updateCargosAfterAddNewAuditors()" />
                        </v-col>

                        <v-col cols="4" v-if="item.responsable && item.auditores">
                          <v-autocomplete
                            v-model="item.responsable.uuid"
                            :label="$t('responsable')"
                            :items="item.auditores"
                            outlined
                            dense
                            :item-text="nombreCompleto"
                            item-value="uuid"
                            :disabled="isDisabled || !item.auditores.length || !canEditAuditores"
                            @change="guardarCambiosAuditoria()"></v-autocomplete>
                        </v-col>

                        <v-col cols="4">
                          <v-btn
                            text
                            block
                            outlined
                            color="primary lighten-2"
                            :disabled="isDisabled"
                            @click="initCargos(), (ui.showCargos = !ui.showCargos)">
                            Gestión de auditores
                            <v-icon right dark> mdi-account-tie </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          class="v-data-table theme--light pt-0"
                          v-if="ui.showCargos && item.cargos">
                          <div class="v-data-table__wrapper">
                            <table
                              style="
                                border: 1px solid #ccc;
                                background: #f9f9f9;
                                border-radius: 3px;
                                box-shadow: 0px 0px 3px 0px #e2e2e2;
                              ">
                              <thead>
                                <tr>
                                  <th class="text-left">Auditor</th>
                                  <th class="text-left">En calidad de</th>
                                </tr>
                              </thead>
                              <tbody>
                                <template v-for="(group, key2) in Object.values(item.cargos)">
                                  <tr v-for="(aud, key) in group" :key="key + aud.auditor">
                                    <td>{{ aud.nombre }}</td>
                                    <td>
                                      <v-col cols="5" class="pl-0">
                                        <SelectEstaticos
                                          v-model="aud.cargo"
                                          :subalcance="item.subalcance"
                                          codigo="cargo-de-auditor"
                                          required
                                          outlined
                                          dense
                                          :itemValue="'valor'"
                                          @change="cargosChanged()"
                                          :estaticos="estaticos"
                                          :key="component" />
                                      </v-col>
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </div>
                        </v-col>

                        <!-- 
                        <v-col cols="12" class="v-data-table theme--light pt-0"
                          v-if="ui.showCargos && (isCcl || isOceGlobal)">
                          <div class="v-data-table__wrapper">
                            <table
                              style="
                                border: 1px solid #ccc;
                                background: #f9f9f9;
                                border-radius: 3px;
                                box-shadow: 0px 0px 3px 0px #e2e2e2;
                              ">
                              <thead>
                                <tr>
                                  <th class="text-left" style="width: 50%">Auditor revisor</th>
                                  <th class="text-left" style="width: 50%">&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody>
                                <template>
                                  <tr>
                                    <td>
                                      <SelectRevisor
                                        v-model="item.revisor"
                                        ::title="$t('false')"
                                        required
                                        :alcance="item.alcance.uuid"
                                        :cliente="item.cliente.uuid"
                                        @change="guardarCambiosAuditoria()" />
                                    </td>
                                    <td></td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </div>
                        </v-col>
                        -->

                        <v-col
                          v-if="ui.showCargos"
                          cols="12"
                          class="v-data-table theme--light pt-0">
                          <div class="v-data-table__wrapper">
                            <table
                              style="
                                border: 1px solid #ccc;
                                background: #f9f9f9;
                                border-radius: 3px;
                                box-shadow: 0px 0px 3px 0px #e2e2e2;
                              ">
                              <thead>
                                <tr>
                                  <th class="text-left">Acompañante</th>
                                  <th class="text-left">En calidad de</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="py-5">
                                    <v-text-field
                                      :label="$t('primer_acompañante')"
                                      v-model="item.acompanante_1"
                                      hide-details="auto"
                                      dense
                                      :disabled="!canEditAuditores"
                                      @change="guardarCambiosAuditoria()"></v-text-field>
                                  </td>
                                  <td class="py-5">
                                    <v-text-field
                                      v-model="item.cargo_acompanante_1"
                                      hide-details="auto"
                                      dense
                                      :disabled="!canEditAuditores"
                                      @change="guardarCambiosAuditoria()"></v-text-field>
                                  </td>
                                </tr>

                                <tr>
                                  <td class="py-5">
                                    <v-text-field
                                      :label="$t('segundo_acompañante')"
                                      v-model="item.acompanante_2"
                                      hide-details="auto"
                                      dense
                                      :disabled="!canEditAuditores"
                                      @change="guardarCambiosAuditoria()"></v-text-field>
                                  </td>
                                  <td class="py-5">
                                    <v-text-field
                                      v-model="item.cargo_acompanante_2"
                                      hide-details="auto"
                                      dense
                                      :disabled="!canEditAuditores"
                                      @change="guardarCambiosAuditoria()"></v-text-field>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="4">
                          <SelectEstaticos
                            v-if="auditoria && auditoria.subalcance"
                            :label="$t('nivel_de_control')"
                            v-model="item.nivel"
                            :outlined="true"
                            :dense="true"
                            :subalcance="auditoria.subalcance"
                            codigo="nivel-de-control"
                            required
                            :disabled="!item || isDisabled || consulta || isCliente"
                            @change="guardarCambiosAuditoria()"
                            :estaticos="estaticos" />
                        </v-col>
                        <v-col cols="4">
                          <SelectEstaticos
                            v-if="item && item.subalcance"
                            :label="$t('tipo_de_auditoría')"
                            v-model="item.tipoVisita"
                            :subalcance="item.subalcance"
                            codigo="tipos-de-auditoria"
                            required
                            :outlined="true"
                            :dense="true"
                            multiple
                            :disabled="!item || isDisabled || consulta || isCliente"
                            @change="guardarCambiosAuditoria()"
                            :estaticos="estaticos"
                            :key="component" />
                        </v-col>

                        <v-col cols="2">
                          <SelectEstaticos
                            v-if="item && item.subalcance"
                            :label="$t('modo_de_auditoría')"
                            v-model="item.modoVisita"
                            :subalcance="item.subalcance"
                            codigo="modos-de-auditoria"
                            required
                            :outlined="true"
                            :dense="true"
                            :disabled="!item || isDisabled || consulta || isCliente"
                            @change="guardarCambiosAuditoria()"
                            :estaticos="estaticos"
                            :key="component" />
                        </v-col>

                        <v-col cols="2" v-if="item && auditoria">
                          <SelectEstaticos
                            v-model="item.laboratorio"
                            :label="$t('laboratorio')"
                            :outlined="true"
                            :dense="true"
                            :alcance="auditoria.subalcance.uuid"
                            codigo="laboratorios"
                            :disabled="!item || auditoriaCerrada || consulta || isCliente"
                            @change="guardarCambiosAuditoria()"
                            :estaticos="estaticos" />
                        </v-col>
                      </v-row>

                      <v-row v-if="cliente" class="mt-6">
                        <v-card flat>
                          <v-card-title>
                            Representantes
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  color="success"
                                  class="ml-2"
                                  v-on="on"
                                  @click="dialogRepresentante = !dialogRepresentante">
                                  mdi-plus
                                </v-icon>
                              </template>
                              <span>Haga clic para crear un representante y añadirlo</span>
                            </v-tooltip>
                          </v-card-title>
                          <v-card-text>
                            <v-data-table
                              :items="item.representantes"
                              :headers="headersRepresentantes"
                              @click:row="editRepresentante"
                              hide-default-footer>
                              <template v-slot:item.firma="{ item }">
                                <template v-if="item.firma">
                                  <v-icon color="success">mdi-check</v-icon>
                                </template>
                                <template v-else>
                                  <v-icon color="error">mdi-close</v-icon>
                                </template>
                              </template>
                              <template v-slot:item.actions="{ item }">
                                <v-btn icon color="error" @click="removeRepresentante(item)">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                            </v-data-table>
                          </v-card-text>
                        </v-card>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <!-- Certificacion && (item.planificaciones.length > 0 && expedienteLoaded) -->

                  <certificado-sites
                    v-if="item && item.subalcance && !isCliente"
                    :auditoria="auditoria"
                    :solicitud="item.solicitud"
                    :iteracion="iteracion"
                    :sites="sitesAvailable"
                    :cliente="cliente"
                    :alcance="item.subalcance.alcance"
                    :subalcance_tipo="item.subalcance.tipo"
                    :planificaciones="item.planificaciones"
                    :disabled="isDisabled">
                  </certificado-sites>

                  <!-- ANOTACIONES -->

                  <v-card class="mt-5" flat v-if="item && !isCliente">
                    <v-card-title> Anotaciones </v-card-title>
                    <v-card-text>
                      <v-textarea
                        outlined
                        v-model="item.anotaciones"
                        :disabled="consulta || isDisabled"
                        @blur="guardarCambiosAuditoria()"></v-textarea>
                    </v-card-text>
                  </v-card>

                  <v-row class="my-5" v-if="item && item.auditores">
                    <v-col cols="4" v-for="(aud, key) in item.auditores" :key="'aud' + key">
                      <v-card
                        flat
                        style="border: 2px solid #ccc; cursor: pointer"
                        @click.stop="$refs['firmaAUD' + key][0].sign()"
                        :disabled="isDisabled">
                        <v-card-text>
                          <Signature
                            :ref="'firmaAUD' + key"
                            :signed="item.firmas"
                            :options="{
                              modulo: 'auditorias',
                              moduloRef: item.uuid,
                              typeOfUser: 'auditor',
                              created_by: aud.uuid,
                              user: aud['@id'],
                              created_name: aud.nombreCompleto,
                            }" />
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="4" v-if="item.auditores.length == 1"></v-col>

                    <v-col cols="4" @click.stop="$refs.firmaCLI.sign()" :disabled="isDisabled">
                      <v-card flat style="border: 2px solid #ccc; cursor: pointer">
                        <v-card-text>
                          <Signature
                            v-if="item.uuid"
                            ref="firmaCLI"
                            :signed="item.firmas"
                            :options="{
                              modulo: 'auditorias',
                              moduloRef: item.uuid,
                              typeOfUser: 'cliente',
                              created_by: item.cliente.uuid,
                              user: item.cliente['@id'],
                              created_name: ui.f.fullName(item.cliente),
                            }" />
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>

                <!-- TAB CHECKLIST -->

                <v-tab-item
                  :transition="false"
                  :reverse-transition="false"
                  key="checklists"
                  class="mt-5"
                  style="background: transparent"
                  v-if="!isCliente">
                  <v-card v-if="item && !isCliente && item.checklistsPuntuacion" flat>
                    <v-card-title>
                      Situación de la auditoria
                      {{
                        ui.preliminarView
                          ? '(preliminar)'
                          : !item.estadoPreliminar
                          ? '(preliminar)'
                          : '(final)'
                      }}
                      <v-chip
                        :color="okChecklists ? 'success lighten-2' : 'error lighten-2'"
                        class="white--text ml-2"
                        small
                        style="float: right">
                        {{ okChecklists ? 'OK' : 'KO' }}
                      </v-chip>
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="item.estadoPreliminar"
                        color="indigo darken-1"
                        raised
                        @click="
                          ui.preliminarView = !ui.preliminarView
                          checklistPuntuacionSelected = null
                          optionSelected = null
                          ui.noConfTable = false
                        "
                        class="white--text ml-2">
                        Cambiar vista a estado {{ ui.preliminarView ? 'final' : 'preliminar' }}
                        <v-icon right dark> mdi-cached </v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="4">
                          <v-data-table
                            :headers="[
                              { text: 'Checklist', value: 'nombre' },
                              { text: 'Puntuacion/Total', value: 'puntosvstotal' },
                              { text: '% Cumplimiento', value: 'percent' },
                              { text: 'Situación', value: 'ok' },
                            ]"
                            :items="situationItems"
                            @click:row="
                              checklistPuntuacionSelected = $event
                              optionSelected = null
                            "
                            hide-default-footer>
                            <template v-slot:item.ok="{ item }">
                              <v-chip
                                :color="item.ok ? 'success lighten-2' : 'error lighten-2'"
                                class="white--text ml-2"
                                small>
                                {{ item.ok ? 'OK' : 'KO' }}
                              </v-chip>
                            </template>
                          </v-data-table>
                        </v-col>
                        <v-col cols="8">
                          <v-card
                            v-if="checklistPuntuacionSelected && checklistPuntuacionSelected.nombre"
                            flat>
                            <v-card-title class="text-center mb-2">
                              {{ checklistPuntuacionSelected.nombre }}
                            </v-card-title>
                            <v-card-text>
                              <v-row>
                                <v-col cols="6">
                                  <span class="text-h6"
                                    >Puntuacion (%): {{ checklistPuntuacionSelected.percent }}</span
                                  >
                                  <br />
                                  <span class="text-h6"
                                    >Numero de no conformidades:
                                    {{ checklistPuntuacionSelected.num_nc }}
                                    <v-btn
                                      v-if="checklistPuntuacionSelected.num_nc > 0"
                                      color="info"
                                      text
                                      small
                                      @click="ui.noConfTable = !ui.noConfTable"
                                      class="white--text ml-2">
                                      Ver
                                    </v-btn>
                                  </span>
                                </v-col>

                                <v-col cols="6">
                                  <v-select
                                    label="Categoría"
                                    v-model="optionSelected"
                                    :items="checklistPuntuacionSelected.options"
                                    outlined
                                    dense></v-select>
                                </v-col>
                              </v-row>

                              <v-fade-transition>
                                <v-row v-if="optionSelected" class="mt-2">
                                  <v-col cols="12">
                                    <v-data-table
                                      :headers="optionSelectedHeaders"
                                      :items="optionSelectedItems"
                                      hide-default-footer>
                                    </v-data-table>
                                  </v-col>
                                  <v-col cols="12">
                                    <span class="text-h6"
                                      >Puntuacion total de {{ optionSelected }}:
                                      {{ checklistPuntuacionSelected[optionSelected].total }}</span
                                    >
                                    <br />
                                    <br />
                                    <span class="text-h6"
                                      >Numero de requisitos {{ optionSelected }}:
                                      {{ checklistPuntuacionSelected[optionSelected].numero }}</span
                                    >
                                  </v-col>
                                </v-row>
                              </v-fade-transition>
                              <v-row>
                                <v-col cols="8" v-if="optionSelected">
                                  <span class="text-h6"
                                    >Nº de no conformidades en esta categoría:
                                    {{ checklistPuntuacionSelected[optionSelected].num_nc }}</span
                                  >
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                          <v-card flat v-else>
                            <v-card-title class="d-flex justify-center align-center">
                              <h3>Seleccione un checklist para ver su puntuacion</h3>
                            </v-card-title>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row v-if="ui.noConfTable">
                        <h2>No conformidades</h2>
                        <v-col cols="12">
                          <v-tabs v-model="tabNC" color="primary" grow>
                            <v-tab
                              v-for="(option, idx) in checklistPuntuacionSelected.options"
                              :key="option">
                              {{ option }}
                            </v-tab>
                          </v-tabs>
                          <v-tabs-items v-model="tabNC">
                            <v-tab-item
                              v-for="option2 in checklistPuntuacionSelected.options"
                              eager
                              :key="option2">
                              <template v-if="checklistPuntuacionSelected[option2].num_nc > 0">
                                <template
                                  v-for="(nc, i) in checklistPuntuacionSelected[option2]
                                    .no_conformidades">
                                  <h3 class="my-5">Nivel {{ i }}</h3>
                                  <v-data-table
                                    :headers="[
                                      { text: 'Requisito', value: 'codigoCalculo', width: '150' },
                                      { text: 'Texto explicativo', value: 'hint' },
                                    ]"
                                    :items="getNoConformidadesForTable(nc)"
                                    hide-default-footer>
                                  </v-data-table>
                                </template>
                              </template>
                              <template v-else>
                                <v-card flat>
                                  <h3 class="text-center mt-8">
                                    No hay no conformidades en esta categoría
                                  </h3>
                                </v-card>
                              </template>
                            </v-tab-item>
                          </v-tabs-items>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <v-card class="mt-5" v-if="item && !isCliente" flat>
                    <v-card-title>
                      Checklists
                      <v-spacer></v-spacer>
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-show="auth.checklists && auth.checklists.canCreate"
                            :disabled="
                              (!ampliacionIsAllowed && (isDisabled || offlineMode)) ||
                              !planificador_auditor ||
                              !checklist_selected.length ||
                              !sites_selected.length
                            "
                            class="white--text mt-4"
                            color="success"
                            fab
                            small
                            depressed
                            style="font-size: 11px"
                            @click="addPlanificacion()"
                            v-on="on">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Añadir</span>
                      </v-tooltip>
                    </v-card-title>
                    <v-card-text>
                      <v-row v-if="auditoriaAbiertaPrevista && !consulta">
                        <v-col cols="12" md="3" class="mb-0 pb-0">
                          <!-- SELECCIÓN DE AUDITOR -->
                          <v-select
                            :label="$t('auditor')"
                            v-model="planificador_auditor"
                            :items="
                              ampliacionIsAllowed
                                ? item.auditores.filter(
                                    (a) => a.uuid == (item.revisor && item.revisor.uuid)
                                  )
                                : item.auditores
                            "
                            item-text="nombreCompleto"
                            dense
                            clearable
                            return-object
                            :disabled="
                              !ampliacionIsAllowed && (isDisabled || offlineMode)
                            "></v-select>
                        </v-col>
                        <v-col cols="12" md="4" class="mb-0 pb-0">
                          <!-- SELECCIÓN DE CHECKLIST -->
                          <v-select
                            :label="$t('listas_de_validación')"
                            v-model="checklist_selected"
                            :items="
                              ampliacionIsAllowed
                                ? checklist.filter((c) => c.is_revision)
                                : checklist
                            "
                            item-text="nombre"
                            dense
                            multiple
                            clearable
                            small-chips
                            deletable-chips
                            return-object
                            :disabled="
                              !ampliacionIsAllowed && (isDisabled || offlineMode)
                            "></v-select>
                        </v-col>
                        <v-col cols="12" md="5" class="mb-0 pb-0">
                          <!-- SELECCIÓN DE SITES -->
                          <SelectEmplazamientos
                            v-model="sites_selected"
                            :items="sitesAvailable"
                            :disabled="!ampliacionIsAllowed && (isDisabled || offlineMode)" />
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" class="mt-0 pt-0">
                          <v-alert
                            v-if="ui.showPlanificacionError"
                            text
                            outlined
                            color="deep-orange"
                            icon="mdi-alert-octagon">
                            <h3>Ha ocurrido un error al eliminar la planificación</h3>

                            <v-row align="center" no-gutters>
                              <v-col class="grow">
                                No se puede borrar una planificacion que contenga pacs
                              </v-col>
                              <v-spacer></v-spacer>
                              <v-col class="shrink">
                                <v-btn outlined @click="ui.showPlanificacionError = false">
                                  Entendido
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-alert>

                          <!-- TABLA DE PLANIFICACIONES -->
                          <datatable
                            :showHeader="false"
                            :headers="[
                              { text: 'Auditor', align: 'left', value: 'auditor.nombreCompleto' },
                              { text: 'Título de la lista', align: 'left', value: 'nombre' },
                              {
                                text: 'Emplazamiento',
                                align: 'left',
                                value: 'emplazamiento.nombre',
                              },
                              { text: '', align: 'left', value: 'actions' },
                            ]"
                            :items="item.checklists"
                            :disableHover="true"
                            :auditoria="item"
                            :checklistRow="true"
                            :key="component"
                            :auth="auth.checklists"
                            @clickRowEdit="clickRowEdit($event)"
                            @clickRowDelete="clickRowDelete($event)">
                            <template v-slot:item.actions="{ item }">
                              <v-row v-if="!consulta && !isDisabled">
                                <v-col cols="6">
                                  <v-icon
                                    v-if="auth.checklists && auth.checklists.canDelete"
                                    small
                                    class="mr-2"
                                    @click.stop="confirmDeletePlanificacion(item)"
                                    style="font-size: 20px">
                                    mdi-delete-outline
                                  </v-icon>
                                </v-col>
                              </v-row>
                            </template>

                            <template v-slot:item.site.nombre="{ item }">
                              <div>
                                {{ item.site.nombre }}
                                <span style="color: rgb(146, 146, 146); font-size: 12px !important">
                                  - {{ findRGSEAA(item.site) }}
                                </span>
                              </div>
                            </template>
                          </datatable>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!-- TAB PACS -->

                <v-tab-item :transition="false" :reverse-transition="false" key="pacs">
                  <v-card class="mt-5" flat>
                    <v-card-title>
                      <span class="mr-2">Listado de PACS</span>
                    </v-card-title>
                    <v-card-text>
                      <datatable
                        :showHeader="false"
                        :headers="headers_pacs"
                        :items="pacs"
                        :auth="auth.pacs"
                        :disableClickRow="true"
                        :disableHover="true"
                        @click:row="clickRowPACS($event)"
                        @clickRowDelete="confirmarBorradoPAC($event)">
                        <template v-slot:item.created_at="{ item }">
                          {{ item.createdAt | formatDate('DD/MM/YYYY') }}
                        </template>
                        <template v-slot:item.no_conformidades="{ item }">
                          <span>{{ getInfoNoConformidades(item) }}</span>
                        </template>
                        <template v-slot:item.no_conformidades_num="{ item }">
                          <span>{{ getNumPac(item) }}</span>
                        </template>
                      </datatable>
                      <v-checkbox
                        v-if = "pacs.length > 0"
                        v-model="item.certificacionRecomendada"
                        label="Certificación recomendada"
                        :disabled="!auth.pacs.canEdit || isDisabled"
                        :color="item.subalcance.color"
                        @change="guardarCambiosAuditoria()"
                        ></v-checkbox>
                    </v-card-text>
                  </v-card>

                  <v-card class="mt-5" flat>
                    <v-card-title>
                      No conformidades pendientes de asignar
                      <v-spacer></v-spacer>
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <!-- <pre>
                        {{ isCliente }} || {{  esResponsable }} {{ !consulta }} {{ auditoriaAbiertaPrevista }}
                        </pre> -->
                          <v-btn
                            v-show="
                              isCliente ||
                              (esResponsable && !consulta && item && auditoriaAbiertaPrevista)
                            "
                            :disabled="isDisabled || !no_conformidades_seleccionadas.length"
                            class="white--text"
                            color="success"
                            fab
                            small
                            depressed
                            @click="crearPAC()"
                            v-on="on">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Crear PAC</span>
                      </v-tooltip>
                    </v-card-title>

                    <!-- LISTADO DE NO CONFORMIDADES -->
                    <v-data-table
                      class="auditoria"
                      v-model="no_conformidades_seleccionadas"
                      :headers="headers_no_conformidades"
                      :items="no_conformidades"
                      item-key="uuid"
                      :single-select="false"
                      :show-select="
                        isCliente ||
                        (esResponsable &&
                          !consulta &&
                          item &&
                          auditoriaAbiertaPrevista &&
                          !isDisabled)
                      "
                      :expanded.sync="expanded"
                      show-expand
                      :page.sync="page"
                      :items-per-page="10"
                      hide-default-footer
                      no-data-text="Listado vacío"
                      @page-count="pageCount = $event">
                      <template v-slot:item.created_at="{ item }">
                        {{ item.createdAt | formatDate('DD/MM/YYYY') }}
                      </template>

                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <v-row class="ma-2">
                            <!-- Titulo -->
                            <v-col cols="12" class="text-center pt-5">
                              <div v-html="item.titulo"></div>
                              <span
                                ><br />
                                <i>{{ item.texto }}</i></span
                              >
                            </v-col>

                            <!-- Divider -->
                            <v-col cols="12">
                              <v-divider></v-divider>
                            </v-col>

                            <!-- Observaciones -->
                            <v-col cols="6">
                              <div class="mb-3 font-weight-bold text-center caption">
                                Observaciones
                              </div>
                              <v-card outlined style="background: none">
                                <v-card-text v-html="item.observacion"></v-card-text>
                              </v-card>
                            </v-col>

                            <!-- Texto de no conformidad -->
                            <v-col cols="6">
                              <div class="mb-3 font-weight-bold text-center caption">
                                Texto de no conformidad
                              </div>
                              <v-card outlined style="background: none">
                                <v-card-text v-html="item.textoNoConformidad"></v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </td>
                      </template>
                    </v-data-table>

                    <div class="text-center pt-3 pb-3">
                      <v-pagination color="info" v-model="page" :length="pageCount"></v-pagination>
                    </div>

                    <v-card-text> </v-card-text>
                  </v-card>
                </v-tab-item>

                <!-- TAB MUESTRAS -->

                <v-tab-item
                  :transition="false"
                  :reverse-transition="false"
                  key="muestras"
                  style="background: rgb(246, 248, 249) !important"
                  v-if="!isCliente">
                  <v-row v-if="item.expediente">
                    <v-col cols="12">
                      <toma-muestras-listado
                        :iteracion="item.iteracion"
                        :expediente="item.expediente.uuid"
                        :auditoria="auditoria"
                        :sitesAvailable="sitesAvailable"
                        v-if="item" />
                    </v-col>
                  </v-row>
                </v-tab-item>

                <!-- TAB BLOQUES -->

                <v-tab-item
                  :transition="false"
                  :reverse-transition="false"
                  key="bloques"
                  class="mt-5"
                  style="background: #f6f8f9">
                  <v-col cols="12">
                    <div v-if="ui.loadingBlocks">
                      Cargando bloques...
                      <v-progress-linear
                        color="deep-purple accent-4"
                        indeterminate
                        rounded
                        height="6"></v-progress-linear>
                    </div>

                    <template v-if="ui.refresh > 0">
                      <!-- {{ db.bloques.length }} -- {{ db.misBloques.length }} -->

                      <v-row
                        v-for="(block, index, i) in db.misBloques"
                        class="my-3"
                        :key="'block-' + index + ui.refresh">
                        <v-col cols="8">
                          <h2>{{ block.titulo }}</h2>
                        </v-col>

                        <v-col
                          cols="6"
                          class="mx-auto px-10 pb-0"
                          v-if="index > 0"
                          :key="'block-divider-' + index"
                          :title="index">
                          <v-divider style="border-style: dashed"></v-divider>
                        </v-col>

                        <v-col cols="8" :key="'block-title-' + index" v-if="index > 0"></v-col>
                        <v-col class="text-right" cols="4" :key="'block-actions-' + index">
                          <span
                            v-if="
                              $refs['blck-' + block.uuid] &&
                              $refs['blck-' + block.uuid][0] &&
                              $refs['blck-' + block.uuid][0].item.updated_at
                            "
                            style="
                              color: rgb(146, 146, 146);
                              font-size: 12px !important;
                              margin-right: 10px;
                            ">
                            Actualizado
                            {{ $moment($refs['blck-' + block.uuid][0].item.updated_at).fromNow() }}
                          </span>

                          <v-btn-toggle v-if="!isDisabled">
                            <v-btn small text @click="updateBlock(block.uuid, block)">
                              <span class="hidden-sm-and-down">Actualizar</span>
                              <v-icon right v-if="!block.isNew" color="info"> mdi-refresh </v-icon>
                            </v-btn>

                            <v-menu offset-y :close-on-content-click="true">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn small icon v-bind="attrs" v-on="on">
                                  <v-icon
                                    right
                                    small
                                    class="ma-0"
                                    v-if="
                                      $refs['blck-' + block.uuid] &&
                                      $refs['blck-' + block.uuid][0] &&
                                      $refs['blck-' + block.uuid][0].item.updated_at
                                    "
                                    color="info">
                                    mdi-chevron-down
                                  </v-icon>
                                  <v-icon right small class="ma-0" v-else>
                                    mdi-chevron-down
                                  </v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-if="db.misBloques.length && !block.isNew"
                                  @click.stop="cloneBlock(block.uuid, block)">
                                  <v-list-item-title> Clonar bloque </v-list-item-title>
                                  <v-list-item-icon>
                                    <v-icon small> mdi-content-copy </v-icon>
                                  </v-list-item-icon>
                                </v-list-item>

                                <v-list-item
                                  v-if="!block.isNew"
                                  color="error darken-3"
                                  @click.stop="deleteBlock(block.uuid, block)">
                                  <v-list-item-title> Eliminar bloque </v-list-item-title>
                                  <v-list-item-icon>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon small v-bind="attrs" v-on="on"> mdi-delete </v-icon>
                                      </template>
                                    </v-tooltip>
                                  </v-list-item-icon>
                                </v-list-item>
                                <v-list-item
                                  v-if="!block.isNew"
                                  color="error darken-3"
                                  @click.stop="resetBlock(block.uuid, block)">
                                  <v-list-item-title> Reiniciar Bloque </v-list-item-title>
                                  <v-list-item-icon>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon small v-bind="attrs" v-on="on">
                                          mdi-restore
                                        </v-icon>
                                      </template>
                                    </v-tooltip>
                                  </v-list-item-icon>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-btn-toggle>
                        </v-col>

                        <v-col cols="12" class="pt-0 mb-4" :key="'block-embed-' + index">
                          <block-embed :ref="'blck-' + block.uuid" :block="block"> </block-embed>
                        </v-col>
                      </v-row>
                      <v-col cols="12">
                        <template v-if="bloquesFaltantes.length > 0">
                          <v-btn
                            @click="ui.dialogBloques = true"
                            class="mt-2"
                            color="primary"
                            small>
                            Añadir bloques
                          </v-btn>
                        </template>
                        <template v-else>
                          <v-alert dense text type="success">
                            Esta auditoría ya cuenta con todos los
                            <strong>bloques</strong> disponibles.
                          </v-alert>
                        </template>
                      </v-col>
                      <v-dialog v-model="ui.dialogBloques" max-width="800px">
                        <v-card>
                          <v-card-title> Añadir bloques </v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12">
                                <v-select
                                  v-model="blockSelected"
                                  :items="bloquesFaltantes"
                                  item-text="titulo"
                                  item-value="uuid"
                                  label="Bloques"
                                  multiple
                                  outlined
                                  dense>
                                </v-select>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="addBlocks()"> Añadir </v-btn>
                            <v-btn color="error" @click="ui.dialogBloques = false">
                              Cancelar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </template>
                  </v-col>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>

    <v-snackbar
      v-if="item && !$nuxt.isOffline"
      v-model="item.bloqueada"
      right
      :timeout="-1"
      :multi-line="true">
      <v-icon color="warning" class="mr-2"> mdi-lock </v-icon>

      <div style="display: inline-flex" v-if="item.bloqueada_por">
        Esta auditoría ha sido bloqueada y no permite cambios.<br />
        Bloqueado por {{ item.bloqueada_por.username }} el
        {{ $moment(item.bloqueada_at).format('D [de] MMMM, YYYY') }}
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="unlockAuditoria"> Desbloquear </v-btn>
      </template>
    </v-snackbar>

    <!-- FORMULARIO PARA INTRODUCIR MOTIVOS DE RECHAZO O NO APTA -->

    <v-dialog v-model="dialogIntroducirMotivo" persistent max-width="40%">
      <v-card v-if="item">
        <v-card-text>
          <v-icon @click="dialogIntroducirMotivo = false" style="float: right">mdi-close</v-icon>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  name="input-7-1"
                  :label="$t('motivo')"
                  v-model="item.motivo"
                  :hint="$t('introduzca_el_motivo_por_el_que_selecciona_esta_opción')"
                  persistent-hint
                  outlined></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mr-2 mb-2" text @click="dialogIntroducirMotivo = false"> Cerrar</v-btn>
          <v-btn
            v-if="revision"
            class="mr-2 mb-2"
            color="error"
            @click="confirmarCambioEstado(estado.ABIERTA)">
            REVISION KO
          </v-btn>
          <v-btn
            v-else-if="dictamen"
            class="mr-2 mb-2"
            color="error"
            @click="confirmarCambioEstado(estado.NO_APTA)">
            NO APTA
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- FORMULARIO PARA INTRODUCIR NUEVOS REPRESENTANTES -->
    <v-dialog v-model="dialogRepresentante" persistent max-width="40%">
      <v-card v-if="item">
        <v-card-title> Añadir representante </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  name="input-7-1"
                  :label="$t('nombre')"
                  v-model="representante.nombre"
                  outlined
                  dense></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  name="input-7-1"
                  :label="$t('email')"
                  v-model="representante.email"
                  outlined
                  dense></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  name="input-7-1"
                  :label="$t('telefono')"
                  v-model="representante.telefono"
                  outlined
                  dense></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  name="input-7-1"
                  :label="$t('cargo')"
                  v-model="representante.cargo"
                  outlined
                  dense></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  name="input-7-1"
                  :label="$t('nif')"
                  v-model="representante.nif"
                  outlined
                  dense></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  name="input-7-1"
                  :label="$t('codigo')"
                  v-model="representante.codigo"
                  outlined
                  dense></v-text-field>
              </v-col>
            </v-row>
            <!-- Firma representante -->
            <v-row>
              <v-col cols="12">
                <v-card flat style="border: 2px solid #ccc; cursor: pointer">
                  <v-card-text>
                    <Signature
                      :ref="'firmaREP'"
                      :signed="representante.firma"
                      :options="{
                        modulo: 'auditorias',
                        moduloRef: item.uuid,
                        typeOfUser: 'representante',
                        created_by: item.cliente.uuid,
                        user: item.cliente['@id'],
                        created_name: representante.nombre,
                      }" />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 mb-2"
            text
            @click="
              dialogRepresentante = false
              clearRepresentante()
            ">
            Cerrar</v-btn
          >
          <v-btn class="mr-2 mb-2" color="success" @click="addRepresentante()"> Añadir </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CONFIRMACIÓN DE BORRADO DE PLANIFICACIÓN -->

    <confirmation
      v-model="dialogConfirmarBorradoPlanificacion"
      text="¿Seguro que desea eliminar la planificación?"
      textButton="Eliminar"
      colorButton="primary"
      @confirm="removePlanificacion()" />

    <!-- CONFIRMACIÓN DE CAMBIO DE ESTADO -->

    <confirmation
      v-model="dialogConfirmarCambioEstado"
      :text="texto_confirmacion"
      textButton="Sí"
      colorButton="primary"
      @confirm="cambiarEstadoAuditoria()">
      <v-card-text>
        <v-form ref="createForm">
          <v-row v-if="!auditoriaCerrada"> </v-row>
        </v-form>
      </v-card-text>
    </confirmation>

    <!-- CONFIRMACIÓN DE BORRADO DE PAC -->

    <confirmation
      v-model="dialogConfirmarBorradoPAC"
      :text="texto_confirmacion"
      textButton="Eliminar"
      colorButton="primary"
      @confirm="deletePAC()" />

    <!-- CONFIRMACIÓN DE CAMBIO A MODO OFFLINE -->

    <confirmation
      v-model="dialogModoOffline"
      :text="texto_confirmacion"
      textButton="Aceptar"
      colorButton="primary"
      @confirm="toggleOffline()" />

    <!-- CONFIRMACIÓN DE CAMBIO A MODO ONLINE -->

    <confirmation
      v-model="dialogModoOnline"
      :text="texto_confirmacion"
      textButton="Aceptar"
      colorButton="primary"
      @confirm="toggleOnline()" />

    <!-- CONFIRMACIÓN DE GUARDAR PRELIMINAR -->
    <confirmation
      v-model="ui.showPreliminarState"
      :text="texto_confirmacion_preliminar"
      textButton="Aceptar"
      colorButton="primary"
      @confirm="savePreliminarState()" />
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \pages\auditorias\_uuid.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Mon Jul 10 2023
 **/

import moment from 'moment'
import text from '~/models/labels'
import format from '~/util/formatHelper'
import COLORS from '~/models/colors'
import Loading from '~/components/Loading'
import Datatable from '~/components/Datatable'
import Confirmation from '~/components/Confirmation'
import Certificacion from '~/components/auditorias/Certificacion'
import Checklist from '~/components/auditorias/Checklist'
// import ChecklistPreguntas from '~/components/auditorias/ChecklistPreguntas'
import Muestras from '~/components/auditorias/muestras/Muestras'
import Estados from '~/models/estados'
import ROLES from '~/models/role'
import MODELS from '~/models/araporcei/solicitudes'

import HeaderComponent from '../../components/HeaderComponent'
import SelectAuditores from '~/components/forms/SelectAuditoresMultiple'
import SelectRevisor from '~/components/forms/SelectAuditores'
import SelectEstaticos from '~/components/forms/SelectEstaticos'
import SelectActividades from '~/components/forms/SelectActividades'
import SelectEmplazamientos from '~/components/forms/SelectEmplazamientos'

import Signature from '~/components/signatures/Show'
import blockEmbed from '~/pages/bloques/widgets/blockEmbed'
import CertificadoSites from '../../components/auditorias/CertificadoSites'

import FloatingButtonSave from '~/components/FloatingButtonSave'
import SelectEmplazamientoProductos from '~/components/auditorias/SelectEmplazamientoProductos.vue'
import PacsListado from '~/pages/auditorias/widgets/pacs.vue'
import TomaMuestrasListado from '~/pages/auditorias/widgets/toma_muestras.vue'
import FormGridDinamico from '~/components/solicitudes/FormGridDinamico.vue'
import { email } from 'vuelidate/lib/validators'

export default {
  props: {
    uuid: {
      type: String,
      default: '',
    },
  },
  components: {
    HeaderComponent,
    Loading,
    Confirmation,
    Datatable,
    Certificacion,
    Checklist,
    // ChecklistPreguntas,
    Muestras,
    SelectAuditores,
    SelectEstaticos,
    SelectActividades,
    SelectEmplazamientos,
    blockEmbed,
    Signature,
    CertificadoSites,
    SelectRevisor,
    FloatingButtonSave,
    SelectEmplazamientoProductos,
    PacsListado,
    TomaMuestrasListado,
    FormGridDinamico,
  },

  data: () => ({
    search: '',
    emplazamiento: null,
    blockSelected: [],
    productos: [],
    configuracion: {
      auditoriaAutoPreliminar: true,
    },
    planes_auditoria: [],
    plan: '',
    plan_grupo: '',
    grupos_planes_auditoria: [],
    tabProductos: '',
    colors: COLORS,
    grids: {},
    selected_emplazamiento: [],
    selected_productos: [],
    tabProductos: null,
    tabNC: null,
    expedienteLoaded: false,
    estaticos: null,
    oldSiteNombre: '',
    menuFechaAuditoria: false,
    create_auditoria: {},
    expanded: [],
    sitesAvailable: [],
    component: null,
    dialogModoOffline: false,
    dialogModoOnline: false,
    valid: false,
    componente_cultivos: null,
    tab: null,
    menuFecha1: false,
    menuFecha2: false,
    menuFechaCierre: false,
    menuFechaInforme: false,
    menuFechaPrevista: false,
    menuFechaAuditoria1: false,
    menuFechaAuditoria2: false,
    selectAllAuditores: false,
    tab_module: '',
    checklistPuntuacionSelected: [],
    item: {
      cliente: { nombre: '' },
      alcance: { nombre: '' },
      cargos: [],
      auditores: [],
      plan_de_auditoria: [],
      responsable: null,
      acompanante_1: null,
      cargo_acompanante_1: null,
      acompanante_2: null,
      cargo_acompanante_2: null,
      sites: {},
      tipo: {},
      nivel: 'Normal',
      estado: 'PREVISTA',
      bloqueada: false,
      emplazamientos: [],
      productos: [],
      checklistsPuntuacion: [],
      certificacionRecomendada: false,
    },
    optionSelected: null,
    auditoria: null,
    cliente: null,
    representante: {
      nombre: '',
      email: '',
      telefono: '',
      cargo: '',
      firma: '',
    },
    iteracion: null,
    auditoria_checklist: [],
    planificador_auditor: null,
    checklist: [],
    checklist_selected: [],
    sites: [],
    sites_selected: [],
    pacs: [],
    selected_pac: null,
    dialogConfirmarBorradoPAC: false,
    no_conformidades: [],
    no_conformidades_seleccionadas: [],
    // headers_checklists: [
    //   { text: 'Auditor', align: 'left', value: 'auditor.nombre' },
    //   { text: 'Título de la lista', align: 'left', value: 'titulo' },
    //   { text: 'Emplazamiento', align: 'left', value: 'site.nombre' },
    //   { text: 'Mayores', align: 'left', value: 'cumplimiento.mayores' },
    //   { text: 'Menores', align: 'left', value: 'cumplimiento.menores' },
    //   { text: 'Completado', align: 'left', value: 'cumplimiento.completados' },
    // ],
    headers_pacs: [
      { text: 'Fecha creación', align: 'left', value: 'created_at' },
      { text: 'No conformidades', align: 'left', value: 'no_conformidades' },
      { text: 'Nº no conformidad', align: 'left', value: 'no_conformidades_num' },
      { text: '', align: 'left', value: 'actions' },
    ],
    headers_no_conformidades: [
      // {text: "Fecha", align: "left", value: "created_at"},
      { text: 'Código pregunta', align: 'left', value: 'codigoCalculo' },
      { text: 'Emplazamiento', align: 'left', value: 'checklist.emplazamiento.nombre' },
      { text: 'Número', align: 'left', value: 'numero' },
      { text: 'Nivel', align: 'left', value: 'nivel' },
    ],
    headersRepresentantes: [
      { text: 'Nombre', align: 'left', value: 'nombre', width: 150 },
      { text: 'NIF', align: 'left', value: 'nif', width: 150 },
      { text: 'Código', align: 'left', value: 'codigo', width: 150 },
      { text: 'Cargo', align: 'left', value: 'cargo', width: 150 },
      { text: 'Email', align: 'left', value: 'email', width: 150 },
      { text: 'Teléfono', align: 'left', value: 'telefono', width: 150 },
      { text: 'Firma', align: 'left', value: 'firma', width: 100 },
      { text: '', align: 'left', value: 'actions', width: 100 },
    ],
    page: 1,
    pageCount: 0,
    estados: Estados.AUDITORIA_ESTADOS,
    estado: Estados.AUDITORIA_ESTADO,
    dialogIntroducirMotivo: false,
    dialogConfirmarCambioEstado: false,
    dialogRepresentante: false,
    texto_confirmacion: '',
    texto_confirmacion_preliminar:
      'Se va a guardar el estado de preliminar. Esto debe hacerse una vez se hayan rellenado los checklists y antes de resolver PACS en caso de que hubiese alguna no conformidad. ¿Desea continuar?',
    revision: false,
    dictamen: false,
    niveles_control: ['Normal', 'Reducido', 'Alto'],
    actividadesETG: MODELS.ACTIVIDADES_ETG,
    roles: ROLES,
    dialogConfirmarBorradoPlanificacion: false,
    current_planificacion: null,
    rules: [(v) => !!v || 'Obligatorio'],
    db: {
      auditores: [],

      bloques: [],
      misBloques: [],
    },
    module: null,

    auth: {},
    snack: false,
    snackColor: '',
    snackText: '',
    oldValOT: null,
    oldValPA: null,

    ui: {
      f: format,
      text: text,
      preliminarView: false,
      refresh: 0,
      loading: false,
      loadingBlocks: false,
      remainDisabled: false,
      noConfTable: false,
      showCargos: false,
      showFechas: false,
      showPlanes: false,
      showValoration: false,
      showPreliminarState: false,
      showPlanificacionError: false,
      panelLateral: false,
      dialogBloques: false,
    },
  }),
  watch: {
    '$store.state.gestor_documental.actualizarEvidencias': function () {
      if (this.$store.state.gestor_documental.actualizarEvidencias) {
        this.$store.commit('gestor_documental/ACTUALIZAR_EVIDENCIAS', false)
        this.getAuditoriaChecklist()
      }
    },
    'actualizarEvidencias'(val) {
      if (val) this.getEvidencias()
    },
    'dialogConfirmarCambioEstado'(val) {
      if (!val) {
        this.create_auditoria = {}
        this.$refs.createForm.reset()
      }
    },
    '$nuxt.isOffline'() {
      if (this.$nuxt.isOffline) {
        this.ui.remainDisabled = true
      }
    },

    'disableSendOT'() {
      if (this.disableSendOT === this.oldValOT) return
      this.oldValOT = this.disableSendOT
      this.$nuxt.$emit('changeStatusOT', this.disableSendOT)
    },

    'disableSendPA'() {
      if (this.disableSendPA === this.oldValPA) return
      this.oldValPA = this.disableSendPA
      this.$nuxt.$emit('changeStatusPA', this.disableSendPA)
    },
  },
  computed: {
    situationItems() {
      let result = {}
      if (this.item.estado === 'CERRADA') {
        if (this.ui.preliminarView) {
          result = Object.assign({}, this.item.estadoPreliminar)
        } else {
          result = Object.assign({}, this.item.estadoFinal)
        }
      } else if (this.ui.preliminarView) {
        result = Object.assign({}, this.item.estadoPreliminar)
      } else {
        result = Object.assign({}, this.item.checklistsPuntuacion)
      }

      if (result && result.global) {
        delete result.global
      }

      return Object.values(result)
    },

    okChecklists() {
      if (!this.item.checklistsPuntuacion) return false
      let checklists = this.ui.preliminarView
        ? this.item.estadoPreliminar
        : this.item.checklistsPuntuacion
      for (let key in checklists) {
        //si key es global, no lo tenemos en cuenta
        if (key === 'global') continue
        if (!checklists[key].ok) return false
      }
      return true
    },
    canSaveEstadoPreliminar() {
      return !this.configuracion.auditoriaAutoPreliminar
    },
    optionSelectedHeaders() {
      if (!this.optionSelected) return []
      let headers = [
        {
          text: 'Requierimiento',
          value: 'colname',
        },
      ]
      let filtered = JSON.parse(
        JSON.stringify(this.checklistPuntuacionSelected[this.optionSelected])
      )
      //remove total and nombre keys from filtered object
      delete filtered['total']
      delete filtered['nombre']
      delete filtered['num_nc']
      delete filtered['no_conformidades']
      delete filtered['numero']
      for (let key in filtered) {
        headers.push({
          text: key,
          value: key,
        })
      }
      return headers
    },
    optionSelectedItems() {
      if (!this.optionSelected) return []
      let colnames = ['Número', 'Puntuacion']
      let items = []
      for (let i = 0; i < colnames.length; i++) {
        let row = {
          colname: colnames[i],
        }
        let colkey = colnames[i] == 'Número' ? 'numero' : 'puntos'
        let filtered = JSON.parse(
          JSON.stringify(this.checklistPuntuacionSelected[this.optionSelected])
        )

        //remove total and nombre keys from filtered object
        delete filtered['total']
        delete filtered['nombre']
        delete filtered['no_conformidades']

        for (let key in filtered) {
          row[key] = filtered[key][colkey]
        }
        items.push(row)
      }

      return items
    },
    isNew() {
      return this.$route.params.uuid == 'nueva' ? true : false
    },
    currentColor() {
      return this.item.subalcance ? this.item.subalcance.color : 'primary'
    },
    currentColorTab() {
      return this.item.subalcance ? this.item.subalcance.color : 'primary'
    },
    columnDefs() {
      let headers = []

      headers = [
        {
          text: 'Texto',
          value: 'texto',
        },
        {
          text: 'Hora',
          value: 'hora',
        },
        {
          text: 'Negrita',
          value: 'negrita',
        },
        {
          text: 'Es Fecha',
          value: 'fecha',
        },
        {
          text: 'Site',
          value: 'site',
        },
      ]

      let col_delete = {
        text: 'Acciones',
        value: 'actions',
      }
      headers.push(col_delete)
      return headers
    },
    //+-------------------------------------------------
    // ampliacionIsAllowed()
    // Read if the auditoria is in a revision (via prop uuid)
    // Check if the user can ampliar the auditoria
    // -----
    // Created on Tue Nov 22 2022
    //+-------------------------------------------------
    ampliacionIsAllowed() {
      if (!this.uuid) return false
      if (!this.auth.canAmpliar) return false

      return true
    },

    bloquesFaltantes() {
      let bloques = []
      if (this.db.bloques.length) {
        bloques = this.db.bloques.filter((bloque) => {
          return !this.db.misBloques.some((misBloque) => {
            return bloque.uuid === misBloque.plantilla.uuid
          })
        })
      }
      return bloques
    },

    //+-------------------------------------------------
    // isDisabled()
    // Casos en los que no se permite la edición de campos
    // -----
    // Created on Thu Oct 13 2022
    //+-------------------------------------------------
    isDisabled() {
      if (this.auditoriaCerrada) return true
      if (!this.auth.canEdit || true) return false

      let isAuditor = this.$auth.user.roles.includes('ROLE_AUDITOR')
      let isTheAuditor = this.$auth.user.uuid === this.item.responsable.uuid
      let isAuditorAdmin = this.$auth.user.roles.includes('ROLE_AUDITOR_ADMIN')
      let isAuditorInAuditores = this.item.auditores.some((auditor) => {
        return this.$auth.user.uuid === auditor.uuid
      })

      if (isAuditor && !isTheAuditor && !isAuditorInAuditores && !isAuditorAdmin) return true

      return false
    },

    isMobile() {
      return this.$device.isTablet || this.$device.isMobile
    },

    isCliente() {
      return this.$store.getters.isCliente
    },

    isAraporcei() {
      return this.$auth.user.empresa.codigo == 'araporcei'
    },

    isCerticalidad() {
      return this.$auth.user.empresa.nombre == 'certicalidad'
    },

    isOceGlobal() {
      return this.$auth.user.empresa.codigo == 'oceglobal'
    },

    isCcl() {
      return this.$auth.user.empresa.nombre == 'Ccl'
    },

    isQuesoManchego() {
      return this.$auth.user.empresa.codigo == 'quesomanchego'
    },

    consulta() {
      return this.revision || this.dictamen
      // return this.dictamen
    },

    actualizarEvidencias() {
      return this.$store.state.gestor_documental.actualizarEvidencias
    },

    offlineMode() {
      return this.$store.getters['auditorias/offlineMode']
    },

    auditoriaAbiertaPrevista() {
      // En revision, permitir edición
      if (this.revision) return true

      const estado = this.item && this.item.estado.toUpperCase()
      return (
        estado === this.estado.CONFIRMADA ||
        estado === this.estado.ABIERTA ||
        estado === this.estado.PREVISTA
      )
    },

    auditoriaCerrada() {
      if (this.item) {
        //this.item.estado = 'prevista'
        return (
          (this.item && this.item.estado.toUpperCase() === this.estado.CERRADA) ||
          (this.item && this.item.estado.toUpperCase() == 'REALIZADA')
        )
      }
    },

    esResponsable() {
      // nota: solo el responsable puede crear PACS!!!
      if (this.item && this.item.responsable) {
        const isResponsable = this.$auth.user.auditor?.uuid === this.item.responsable.uuid
        const isResponsableUser = this.$auth.user.uuid === this.item.responsable.user_uuid
        const isRoot =
          this.$auth.user.roles.includes(this.roles.ROLE_ROOT) ||
          this.$auth.user.roles.includes(this.roles.ROLE_ADMIN) ||
          this.$auth.user.roles.includes(this.roles.ROLE_ADMIN_GROUP)
        const isAuditorAdmin = this.$auth.user.roles.includes(this.roles.ROLE_AUDITOR_ADMIN)

        return isResponsable || isResponsableUser || isRoot || isAuditorAdmin
      } else return false
    },

    canEditAuditores() {
      return this.auth.canEdit
    },

    //+-------------------------------------------------
    // isLocked()
    // Returns true when the item is locked and the app is not offline
    // -----
    // Created on Wed Feb 02 2022
    //+-------------------------------------------------
    isLocked() {
      return !$nuxt.isOffline && this.item && this.item.bloqueada
    },

    disableSendOT() {
      if (
        !this.isNew &&
        this.item.cargos &&
        Object.keys(this.item.cargos).length > 0 &&
        this.item.fechas &&
        this.item.fechas.length
      ) {
        return false
      } else return true
    },

    disableSendPA() {
      if (
        !this.isNew &&
        this.item.cargos &&
        Object.keys(this.item.cargos).length > 0 &&
        this.item.planAuditoria &&
        this.item.planAuditoria.length
      ) {
        return false
      } else return true
    },
  },
  methods: {
    editRepresentante(item) {
      this.dialogRepresentante = true
      this.representante = item
      this.$nextTick(() => {
        if (this.$refs['firmaREP']) {
          this.$refs['firmaREP'].signature = item.firma
        }
      })
    },
    clearRepresentante() {
      this.representante = {
        nombre: '',
        email: '',
        telefono: '',
        cargo: '',
        firma: '',
      }
      this.$refs['firmaREP'].signature = false
    },
    addRepresentante() {
      //get the signature
      this.representante.firma = this.$refs['firmaREP'].signature
      //reset the signature
      this.$refs['firmaREP'].signature = false
      this.item.representantes.push(this.representante)
      this.representante = {
        nombre: '',
        email: '',
        telefono: '',
        cargo: '',
        firma: '',
      }
      this.dialogRepresentante = false
    },
    removeRepresentante(index) {
      this.item.representantes.splice(index, 1)
    },
    getNoConformidadesForTable(nc) {
      let no_conformidades = []
      //nc is an object, we need to convert it to an array
      for (let key in nc) {
        let no_conformidad = nc[key]
        no_conformidades.push(no_conformidad)
      }
      return no_conformidades
    },
    savePreliminarState() {
      this.ui.loading = true
      this.ui.showPreliminarState = false
      let body = {
        uuid: this.item.uuid,
        estadoPreliminar: this.item.checklistsPuntuacion,
        okInicial: this.okChecklists,
      }
      this.$store.dispatch('auditorias/setEstadoPreliminar', body).then((response) => {
        this.ui.loading = false
      })
      // this.guardarCambiosAuditoria()
    },
    saveFinalState() {
      this.ui.loading = true
      let body = {
        uuid: this.item.uuid,
        estadoFinal: this.item.checklistsPuntuacion,
        okInicial: this.okChecklists,
      }
      this.$store.dispatch('auditorias/setEstadoFinal', body).then((response) => {
        this.ui.loading = false
      })
    },

    clickRowChecklist(item) {
      this.checklistsPuntuacionSelected = item
    },
    updateSelectedProductos(productos) {
      this.selected_productos = productos
    },
    updateSelectedEmplazamientos(emplazamientos) {
      this.selected_emplazamiento = emplazamientos
    },

    nombreCompleto(item) {
      return format.fullName(item, { is: 'auditor' })
    },

    restrainAuditores(item) {
      /* if (!this.isSAE) return true

      let categorias = []
      if (this.solicitud.alcance_producto.length) {
        categorias = this.solicitud.alcance_producto
          .map((item) => item.categoria_productos)
          .filter((value, index, self) => self.indexOf(value) === index)
      }
      conso

      let valid = true
      for (const categoria of categorias) {
        for (const afinidad of item.afinidades) {
          if (typeof afinidad.categoria == 'string') afinidad.categoria = [afinidad.categoria]
          if (afinidad.categoria && afinidad.categoria.indexOf(categoria) == -1) {
            valid = false
            break
          }
        }
      } */
      /*
      let valid = false
      for (const afinidad of item.afinidades) {
        if (afinidad.subalcance && afinidad.subalcance.uuid == this.item.subalcance.uuid) {
          if (afinidad.hasta) {
            let hasta = this.$moment(afinidad.hasta)
            let hoy = this.$moment()
            if (hasta.isAfter(hoy) || hasta.isSame(hoy)) {
              valid = true
              break
            }
          }
        }
      }

      for (const incompatibilidad of item.incompatibilidades) {
        if (
          incompatibilidad.subalcance &&
          incompatibilidad.subalcance.uuid == this.item.subalcance.uuid &&
          incompatibilidad.cliente.uuid == this.cliente.uuid
        ) {
          if (incompatibilidad.hasta) {
            let hasta = this.$moment(incompatibilidad.hasta)
            let hoy = this.$moment()
            if (hasta.isAfter(hoy) || hasta.isSame(hoy)) {
              valid = false
              break
            }
          }
        }
      }
      */

      return true
    },

    //+-------------------------------------------------
    // findRGSEAA()
    // Tries to find rgseaa data from another source
    // this could be in format helper
    // -----
    // Created on Tue Jul 26 2022
    //+-------------------------------------------------
    findRGSEAA(item) {
      if (item.data?.rgseaa) return item.data.rgseaa
      if (item.data?.instalacion_codigo) return item.data.instalacion_codigo

      const instalacion = this.item.instalaciones.find((i) => i.uuid === item.uuid)

      if (instalacion) {
        if (instalacion.rgseaa) return instalacion.rgseaa
        if (instalacion.instalacion.rgseaa) return instalacion.instalacion.rgseaa
      }

      return ''
    },

    //+-------------------------------------------------
    // horaToTime()
    // Returns a time string to use with input time
    // -----
    // Created on Wed May 25 2022
    //+-------------------------------------------------
    horaToTime(time) {
      if (!time) return ''

      if (time && time.length > 5) {
        return time.substring(0, 5)
      }
      return time
    },

    //+-------------------------------------------------
    // setTime()
    // Sets time value to input with ddbb format friendly
    // -----
    // Created on Wed May 25 2022
    //+-------------------------------------------------
    setTime(field, value) {
      let time = value

      if (value && value.length < 6) {
        time = value + ':00'
      }

      if (value == '' || value == undefined || !value) {
        time = null
      }

      this.item[field] = time
      this.setTramos()
      this.guardarCambiosAuditoria()
    },

    //+-------------------------------------------------
    // setTramos()
    // For orceglobal, defines three entries in fechas
    // -----
    // Created on Wed Mar 15 2023
    //+-------------------------------------------------
    setTramos() {
      if (!this.isOceGlobal) return
      if (!this.item.desdeInicio || !this.item.desdeFin) return

      this.ui.showFechas = true
      if (this.item.fechas.length > 0) return

      let date = this.$moment(this.item.desde).format('YYYY-MM-DD')
      let start = this.$moment(date + ' ' + this.item.desdeInicio)
      let end = this.$moment(date + ' ' + this.item.desdeFin)
      // debugger
      this.item.fechas.push({
        desde: date,
        d_inicio: start.format('HH:mm'),
        d_fin: start.add(15, 'minutes').format('HH:mm'),
        hasta: date,
        h_inicio: start.subtract(15, 'minutes').format('HH:mm'),
        h_fin: start.add(15, 'minutes').format('HH:mm'),
        model: false,
        model_: false,
      })

      this.item.fechas.push({
        desde: date,
        d_inicio: start.format('HH:mm'),
        d_fin: end.subtract(15, 'minutes').format('HH:mm'),
        hasta: date,
        h_inicio: start.format('HH:mm'),
        h_fin: end.format('HH:mm'),
        model: false,
        model_: false,
      })

      this.item.fechas.push({
        desde: date,
        d_inicio: end.format('HH:mm'),
        d_fin: end.add(15, 'minutes').format('HH:mm'),
        hasta: date,
        h_inicio: end.subtract(15, 'minutes').format('HH:mm'),
        h_fin: end.add(15, 'minutes').format('HH:mm'),
        model: false,
        model_: false,
      })
    },

    //+-------------------------------------------------
    // addFecha()
    // Adds a new array in this.item and adds one row
    // -----
    // Created on Mon May 16 2022
    //+-------------------------------------------------
    addFecha() {
      if (!this.item.fechas) {
        this.item.fechas = []
      }

      this.item.fechas.push({
        desde: this.$moment(this.item.desde).format('YYYY-MM-DD'),
        d_inicio: '07:00',
        d_fin: '15:00',
        hasta: this.$moment(this.item.hasta).format('YYYY-MM-DD'),
        h_inicio: '15:00',
        h_fin: '21:00',
        modo: this.item.modoVisita
          ? this.item.modoVisita == 'In Situ'
            ? 'In Situ'
            : this.item.modoVisita == 'Remoto'
            ? 'Remoto'
            : 'Combinada'
          : '',
        model: false,
        model_: false,
      })

      this.$forceUpdate()
    },

    //+-------------------------------------------------
    // removeFecha()
    // Removes a row from this.item.fechas
    // -----
    // Created on Mon May 16 2022
    //+-------------------------------------------------
    removeFecha(index) {
      this.item.fechas.splice(index, 1)

      this.guardarCambiosAuditoria()
      this.$forceUpdate()
    },

    async getEstaticos() {
      const body = {
        'empresa': this.$auth.user.empresa.uuid,
        'codigo': ['tipos-de-auditoria', 'nivel-de-control', 'laboratorios'],
        'subalcance.uuid': this.item.subalcance.uuid,
      }

      const xhr = this.offlineMode
        ? await this.$store.dispatch('offlineMode/getEstaticos', body)
        : await this.$store.dispatch('estaticos/list', body)
      if (xhr) this.estaticos = xhr.data['hydra:member']
    },

    gotoExpediente() {
      this.$router.push(`/expedientes/${this.item.expediente.uuid}`)
    },

    async updatSiteNombre(item, open) {
      const dialog = 'siteNombre' + item.uuid
      if (open) this.oldSiteNombre = this.$refs[dialog].returnValue
      else {
        try {
          const body = {
            ...item.site,
          }
          await this.$store.dispatch('sites/update', body)
        } catch (e) {
          item.site.nombre = this.oldSiteNombre
          this.component++
        }
      }
    },

    capitalizeFirstLetter(string) {
      return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : ''
    },

    initCargos() {
      if (this.item.cargos && Object.keys(this.item.cargos).length > 0) {
        console.log('initCargos tengo length', this.item.cargos)
        let plainAuditores = []
        for (const aud of Object.keys(this.item.cargos)) {
          for (const audCargo of this.item.cargos[aud]) {
            plainAuditores.push(audCargo)
          }
        }
        if (plainAuditores.length != this.item.auditores.length) {
          console.log('initCargos length diferente', this.item.cargos)
          console.log('item.auditores', this.item.auditores)

          this.updateCargosAfterAddNewAuditors()
        } else {
          this.guardarCambiosAuditoria()
          return
        }
      }
      if (!this.item.cargos || Object.keys(this.item.cargos).length > 0) {
        this.item.cargos = []
        this.updateCargos()
      }
    },

    //+-------------------------------------------------
    // updateCargos()
    // Initializes the array of cargos with the selected auditores
    // -----
    // Created on Mon Feb 14 2022
    //+-------------------------------------------------
    updateCargos() {
      console.log('updateCargos', this.item.cargos)
      if (
        (this.item.cargos && Object.keys(this.item.cargos).length > 0) ||
        typeof this.item.cargos === 'undefined'
      )
        return
      let cargosAux = this.item.cargos
      /*
      for (const aud of this.item.auditores) {
        this.item.cargos.push({
          auditor: aud.uuid,
          nombre: aud.nombreCompleto,
          apellido: aud.apellido,
          cargo: aud.cargo ? aud.cargo : 'Auditor',
        })
          */
      for (const aud of this.item.auditores) {
        const cargoKey = 'observer'
        if (!cargosAux[cargoKey]) cargosAux[cargoKey] = []
        cargosAux[cargoKey].push({
          auditor: aud.uuid,
          nombre: aud.nombreCompleto,
          cargo: cargoKey,
        })
      }
      this.item.cargos = Object.assign({}, cargosAux)
      console.log(this.item.cargos)
      this.guardarCambiosAuditoria()
      this.$forceUpdate()
    },

    cargosChanged() {
      console.log(this.item.cargos)
      let auxAuditores = []
      for (const cargoKey of Object.keys(this.item.cargos)) {
        for (const aud of this.item.cargos[cargoKey]) {
          auxAuditores.push(aud)
        }
      }
      let auxAuditores2 = []
      for (const auditor of auxAuditores) {
        if (!auxAuditores2[auditor.cargo]) {
          auxAuditores2[auditor.cargo] = []
        }
        auxAuditores2[auditor.cargo].push(auditor)
      }
      console.log(auxAuditores2)
      this.item.cargos = Object.assign({}, auxAuditores2)
      this.$forceUpdate()
      this.guardarCambiosAuditoria()
    },

    updateCargosAfterAddNewAuditors() {
      let auditoresRestantes = []
      for (const aud of this.item.auditores) {
        let found = false
        for (const cargoKey of Object.keys(this.item.cargos)) {
          for (const audCargo of this.item.cargos[cargoKey]) {
            if (audCargo.auditor == aud.uuid) {
              found = true
              break
            }
          }
          if (found) break
        }
        if (!found) auditoresRestantes.push(aud)
      }
      console.log('auditoresRestantes', auditoresRestantes)
      for (const aud of auditoresRestantes) {
        /*
        this.item.cargos.push({
          auditor: aud.uuid,
          nombre: aud.nombreCompleto,
          apellido: aud.apellido,
          cargo: aud.cargo ? aud.cargo : 'Auditor',
        })

        */

        const cargoKey = 'observer'
        if (!this.item.cargos[cargoKey]) this.item.cargos[cargoKey] = []
        this.item.cargos[cargoKey].push({
          auditor: aud.uuid,
          nombre: aud.nombreCompleto,
          cargo: cargoKey,
        })
      }
      let auditoresSobrantes = []
      for (const cargoKey of Object.keys(this.item.cargos)) {
        for (const audCargo of this.item.cargos[cargoKey]) {
          let found = false
          for (const aud of this.item.auditores) {
            if (audCargo.auditor == aud.uuid) {
              found = true
              break
            }
          }
          if (!found) auditoresSobrantes.push(audCargo)
        }
      }

      for (const aud of auditoresSobrantes) {
        for (const cargoKey of Object.keys(this.item.cargos)) {
          this.item.cargos[cargoKey] = this.item.cargos[cargoKey].filter(
            (item) => item.auditor !== aud.auditor
          )
        }
      }

      console.log('cargos after for', this.item.cargos)
      this.guardarCambiosAuditoria()
      this.$forceUpdate()
    },

    //+-------------------------------------------------
    // formatHours()
    // Returns only hours for input type time
    // -----
    // Created on Fri Dec 10 2021
    //+-------------------------------------------------
    formatHours() {
      this.item.desde = this.item.desde ? this.$moment(this.item.desde).format('YYYY-MM-DD') : null
      this.item.hasta = this.item.hasta ? this.$moment(this.item.hasta).format('YYYY-MM-DD') : null

      this.item.desdeInicio = this.item.desdeInicio
        ? this.$moment(this.item.desdeInicio).utc().format('HH:mm:ss')
        : null

      this.item.desdeFin = this.item.desdeFin
        ? this.$moment(this.item.desdeFin).utc().format('HH:mm:ss')
        : null

      this.item.hastaInicio = this.item.hastaInicio
        ? this.$moment(this.item.hastaInicio).utc().format('HH:mm:ss')
        : null

      this.item.hastaFin = this.item.hastaFin
        ? this.$moment(this.item.hastaFin).utc().format('HH:mm:ss')
        : null
    },

    async getSubalcance() {
      const res = await this.$store.dispatch('subalcances/get', this.$route.query.subalcance)
      if (res) {
        this.item.subalcance = res
      }
    },

    async getExpediente() {
      const res = await this.$store.dispatch('expedientes/expediente', this.$route.query.expediente)
      if (res) {
        this.item.expediente = res
      }
    },
    save() {
      this.snack = true
      this.snackColor = 'success'
      this.snackText = 'Data saved'
    },
    cancel() {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Canceled'
    },
    open() {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Dialog opened'
    },
    close() {},
    addPlan(plan) {
      if (!plan) return
      let item = this.planes_auditoria.find((item) => item.uuid === plan)
      this.item.plan_de_auditoria.push(item)
      this.plan = ''
      this.component++
      this.$forceUpdate()
    },
    addPlanGrupo(plan) {
      if (!plan) return
      let item = this.grupos_planes_auditoria.find((item) => item.uuid === plan)

      item.lineas.forEach((item) => {
        let linea = {
          orden: item.orden,
          texto: item.lineaPlan.texto,
          hora: item.lineaPlan.hora,
          negrita: item.lineaPlan.negrita,
          fecha: item.lineaPlan.fecha,
          site: item.lineaPlan.site,
          uuid: item.lineaPlan.uuid,
        }
        this.item.plan_de_auditoria.push(linea)
      })
      //this.item.plan_de_auditoria.push(item)
      this.plan_grupo = ''
      this.component++
      this.$forceUpdate()
    },
    moveUp(item) {
      const index = this.item.plan_de_auditoria.indexOf(item)
      if (index > 0) {
        const temp = this.item.plan_de_auditoria[index - 1]
        this.$set(this.item.plan_de_auditoria, index - 1, item)
        this.$set(this.item.plan_de_auditoria, index, temp)
        this.component++
      }
    },
    moveDown(item) {
      const index = this.item.plan_de_auditoria.indexOf(item)
      if (index < this.item.plan_de_auditoria.length - 1) {
        const temp = this.item.plan_de_auditoria[index + 1]
        this.$set(this.item.plan_de_auditoria, index + 1, item)
        this.$set(this.item.plan_de_auditoria, index, temp)
        this.component++
      }
    },
    removeRow(item) {
      const index = this.item.plan_de_auditoria.indexOf(item)
      if (index > -1) {
        this.item.plan_de_auditoria.splice(index, 1)
        this.component++
      }
    },

    async getPlanes() {
      let params = {}

      const res = await this.$store.dispatch('lineas_plan_auditorias/get_planes', params)
      const xhr = await this.$store.dispatch('lineas_plan_auditorias/get_grupos', params)
      if (res) {
        this.planes_auditoria = res
      }
      if (xhr) {
        this.grupos_planes_auditoria = xhr
      }
      /* if (this.item.plan_de_auditoria.length > 0) {
        this.item.plan_de_auditoria.forEach((plan) => {
          this.planes_auditoria = this.planes_auditoria.filter((item) => item.uuid !== plan.uuid)
        })
      } */
    },

    async getOne() {
      this.component++
      const uuid = this.uuid ? this.uuid : this.$route.params.uuid
      if (this.uuid) this.revision = true
      else this.$store.commit('menu/setPageTitle', 'Auditoría')
      if (!this.isNew) {
        const res = await this.$store.dispatch('auditorias/get', uuid)

        if (!res.representante) res.representante = {}
        if (!res.representantes) res.representantes = []
        if (res) {
          this.item = res
          this.auditoria = res
        }

        this.item.plan_de_auditoria = []

        if (res.estado == 'CERRADA') this.item.estado = 'REALIZADA'

        this.formatHours()

        //await this.getFichasCultivos()
        await this.getChecklist()
        await this.getAuditoriaChecklist()
        await this.getSites()
        await this.getCliente()
        // PACS
        await this.getPACS()
        await this.getListadoNoConformidadesPendientesPac()
        //await this.getPlantillaBloques()
        await this.getPlantillasBloques()
        await this.getBloques()
      }
      if (this.$route.query.iteracion) {
        //get iteracion
        await this.getIteracion()
      }
      if (this.$route.query.expediente) {
        //get iteracion
        await this.getExpediente()
      }
      if (this.$route.query.cliente) {
        //get iteracion
        await this.getCliente()
      }
      if (this.$route.query.subalcance) {
        //get iteracion
        await this.getSubalcance()
      }

      if (this.item.acompanantes) {
        this.item.acompanantes.forEach((item, index) => {
          if (index == 0) {
            this.item.acompanante_1 = item.acompanante
            this.item.cargo_acompanante_1 = item.cargo
          }
          if (index == 1) {
            this.item.acompanante_2 = item.acompanante
            this.item.cargo_acompanante_2 = item.cargo
          }
        })
      }

      if (this.item.representante) {
        this.item.representantenombre = this.item.representante.nombre
        this.item.emailRepresentante = this.item.representante.email
        this.item.telefonoRepresentante = this.item.representante.telefono
        this.item.nifRepresentante = this.item.representante.nif
      }

      if (this.item.planAuditoria) {
        this.item.plan_de_auditoria = this.item.planAuditoria
      }

      // Offline
      // if (this.offlineMode) {
      //   // Auditores
      //   await this.$store.dispatch('offlineMode/getAuditores')
      //   // Muestras
      //   const tomas_muestra = await this.$store.dispatch('tomas_muestra/list', uuid)
      //   if (tomas_muestra) {
      //     for (let i = 0; i < tomas_muestra.length; i++) {
      //       const element = tomas_muestra[i]
      //       await this.$store.dispatch('tomas_muestra/listMuestra', element.uuid)
      //     }
      //   }
      //   // Estaticos
      //   const body = {
      //     offlineMode: this.offlineMode,
      //     empresa: this.$auth.user.empresa,
      //     alcance: this.auditoria.alcance.uuid,
      //     codigos: ['tipos_auditorias', 'checklist_niveles', 'laboratorios', 'datos_muestra'],
      //   }
      //   this.estaticos = await this.$store.dispatch('offlineMode/getEstaticos', body)
      // }
      // Formatos
      //this.loadFormatosData()

      // Bloques Añadido el 05/07/2021
      this.component++
      //debugger
    },
    async getIteracion() {
      const res = this.offlineMode
        ? await this.$store.dispatch('offlineMode/getIteracion', this.$route.query.iteracion)
        : await this.$store.dispatch('expedientes/iteracion', this.$route.query.iteracion)
      if (res) {
        let iteracion = res
        this.item.productos = iteracion.productos
        this.item.emplazamientos = iteracion.emplazamientos
        this.item.emplazamientos.forEach((emplazamiento) => {
          if (!emplazamiento.productos) {
            emplazamiento.productos = []
            this.item.productos.forEach((producto) => {
              if (producto.emplazamiento.uuid == emplazamiento.uuid) {
                emplazamiento.productos.push(producto)
              }
            })
          }
        })
      }
    },
    async getSites() {
      /* const res = this.offlineMode
        ? await this.$store.dispatch('offlineMode/getSites', [
            this.item.expediente.uuid,
            this.item.iteracion.uuid,
          ])
        : await this.$store.dispatch('expedientes/iteracion', [
            this.item.expediente.uuid,
            this.item.iteracion.uuid,
          ]) */
      const res = await this.$store.dispatch('expedientes/iteracion', this.item.iteracion.uuid)
      if (res) {
        this.iteracion = res
      }
      let sites = []
      this.item.emplazamientos.forEach((emplazamiento) => {
        if (!emplazamiento.productos) {
          emplazamiento.productos = []
          this.item.productos.forEach((producto) => {
            if (producto.emplazamiento.uuid == emplazamiento.uuid) {
              emplazamiento.productos.push(producto)
            }
          })
        }
      })

      sites = this.item.emplazamientos
      this.sitesAvailable = sites

      this.expedienteLoaded = true
    },
    // async getSites2(){
    //   let vm = this
    //   const res =  vm.offlineMode
    //     ? await vm.$store.dispatch("offlineMode/getSites", [vm.item.expediente.uuid, vm.item.iteracion.uuid])
    //     : await vm.$store.dispatch("expedientes/iteracion", [vm.item.expediente.uuid, vm.item.iteracion.uuid])
    //   if (res) {
    //     vm.iteracion = res
    //     // Añadimos sites de CENTRALES
    //     vm.sites.push({ header: 'CENTRALES' })
    //     if (vm.iteracion.sites && vm.iteracion.sites.CENTRALES && vm.iteracion.sites.CENTRALES.rows){
    //       await vm.iteracion.sites.CENTRALES.rows.forEach(site => {
    //         vm.sites.push(site)
    //       });
    //     } // Añadimos sites de PRODUCTORES
    //     vm.sites.push({ divider: true })
    //     vm.sites.push({ header: 'PRODUCTORES' })
    //     if (vm.iteracion.sites && vm.iteracion.sites.PRODUCTORES && vm.iteracion.sites.PRODUCTORES.rows){
    //       await vm.iteracion.sites.PRODUCTORES.rows.forEach(site => {
    //         vm.sites.push(site)
    //       });
    //     } // Añadimos sites de PRODUCTOS
    //     vm.sites.push({ divider: true })
    //     vm.sites.push({ header: 'PRODUCTOS' })
    //     if (vm.iteracion.sites && vm.iteracion.sites.PRODUCTOS && vm.iteracion.sites.PRODUCTOS.rows){
    //       await vm.iteracion.sites.PRODUCTOS.rows.forEach(site => {
    //         site.nombre = site.nombre + ' '
    //         vm.sites.push(site)
    //       });
    //     }
    //   }
    // },
    async checkOffline() {
      const offlineMode = localStorage.getItem('offlineMode')
      const offlineUuid = localStorage.getItem('offlineUuid')
      if (offlineMode && offlineUuid) this.toggleOffline(offlineUuid)
      else this.getOne()
    },
    confirmarModoOffline() {
      this.texto_confirmacion = '¿Está seguro de que desea entrar en Modo Offline?'
      this.dialogModoOffline = true
    },

    confirmarModoOnline() {
      this.texto_confirmacion = '¿Está seguro de que desea volver al Modo Online?'
      this.dialogModoOnline = true
    },

    async toggleOffline(uuid) {
      this.dialogModoOffline = false
      const auditoria_uuid = uuid ? uuid : this.$route.params.uuid
      await this.$store.commit('auditorias/OFFLINE_MODE', true)
      await this.$store.commit('auditorias/OFFLINE_UUID', auditoria_uuid)
      localStorage.setItem('offlineMode', true)
      localStorage.setItem('offlineUuid', auditoria_uuid)
      this.getOne()
    },
    async toggleOnline() {
      this.dialogModoOnline = false
      await this.$store.dispatch('auditorias/goOnline')
      this.getOne()
    },
    setTabUrl() {
      window.history.pushState(
        { key: Date.now().toFixed(3) },
        '',
        this.$route.path + '?tab=' + this.tab
      )
      if (this.tab === 1 || this.tab === 2) this.getAuditoriaChecklist() // Obtenemos el listado de checklist de la auditoria
      if (this.tab === 3) this.getPACS(), this.getListadoNoConformidadesPendientesPac()
    },
    async getFichasCultivos() {
      let alcance = this.offlineMode
        ? await this.$store.dispatch('offlineMode/getAlcance', this.item.alcance.uuid)
        : await this.$store.dispatch('alcances/get', this.item.alcance.uuid)

      if (alcance) {
        let grupo_alcance = alcance.nombre.replace(' ', '_').toLowerCase()
        let empresa = this.$auth.user.empresa.codigo
        this.componente_cultivos = () =>
          import(`~/pages/auditorias/${empresa}/${grupo_alcance}/cultivos`)
      }
      this.item.alcance = alcance
    },
    async getChecklist() {
      const res = this.offlineMode
        ? await this.$store.dispatch('offlineMode/getPlantillasChecklist')
        : await this.$store.dispatch('plantillas-checklist/list', this.item.subalcance.uuid)
      if (res) this.checklist = res.filter((element) => element.activa)
    },
    async getAuditoriaChecklist() {
      if (this.item) {
        const res = this.offlineMode
          ? await this.$store.dispatch('offlineMode/getAuditoriaChecklist', this.item.uuid)
          : await this.$store.dispatch('checklists/list', this.item.uuid)
        if (res) {
          for (let index = 0; index < res.length; index++) {
            let checklist = res[index]
            checklist.cumplimiento = await this.$store.dispatch(
              'auditorias/cumplimientoChecklist',
              checklist.preguntas
            )
          }
          this.auditoria_checklist = res
        }
      }
    },
    async getEvidencias() {
      await this.$store.dispatch(`gestor_documental/list`, this.$route)
    },
    async getCliente() {
      if (this.item && this.item.cliente && !this.isNew) {
        const res = this.offlineMode
          ? await this.$store.dispatch('offlineMode/getCliente', this.item.cliente.uuid)
          : await this.$store.dispatch('clientes/get', this.item.cliente.uuid)
        if (res) {
          this.cliente = res
          // this.item.cliente.nombre = this.cliente.nombre_completo
        }
      }
      if (this.$route.query.cliente) {
        const res = this.offlineMode
          ? await this.$store.dispatch('offlineMode/getCliente', this.$route.query.cliente)
          : await this.$store.dispatch('clientes/get', this.$route.query.cliente)
        if (res) {
          this.cliente = res
          // this.item.cliente.nombre = this.cliente.nombre_completo
        }
      }
      this.configuracion = this.cliente.empresa.configuracion
    },
    formatDate(date) {
      return date
        ? moment(date.substring(0, 10)).format('DD-MM-YYYY')
        : moment().format('DD-MM-YYYY')
    },

    formatDateGestionFechas(date) {
      if (date) {
        return moment(date).format('DD-MM-YYYY')
      } else {
        return this.auditoria.createdAt
          ? moment(this.auditoria.createdAt).format('DD-MM-YYYY')
          : moment().format('DD-MM-YYYY')
      }
    },

    async addPlanificacion() {
      // Nos quedamos solo con los childrens
      const childrenSites = this.sites_selected.filter((e) => e.parent_uuid)
      const sites = childrenSites.length ? childrenSites : this.sites_selected

      for (let i = 0; i < this.checklist_selected.length; i++) {
        const checklist = this.checklist_selected[i]
        for (let k = 0; k < sites.length; k++) {
          const site = sites[k]

          const planificacion = {
            auditorChecklist: this.planificador_auditor['@id'],
            emplazamientoChecklist: site['@id'],
            plantillaChecklist: checklist['@id'],
          }
          // Añadimos la planificación
          const body = { auditoria: this.item.uuid, planificacion: planificacion }
          const res = await this.$store.dispatch(`auditorias/addPlanificacion`, body)
          if (res) this.item.planificaciones = res.planificaciones
        }
      }
      this.planificador_auditor = null
      this.checklist_selected = []
      this.sites_selected = []
      if (this.offlineMode) localStorage.setItem('auditoria', JSON.stringify(this.item))
      this.getOne()
    },
    removeSiteSelected(item) {
      const index = this.sites_selected.indexOf(item)
      if (index >= 0) this.sites_selected.splice(index, 1)
      if (this.offlineMode) localStorage.setItem('auditoria', JSON.stringify(this.item))
    },
    clickRowEdit(row) {
      this.$router.push({
        path: `/auditorias/tabs/checklist/${row.uuid}`,
        query: { auditoria_uuid: this.item.uuid },
      })
    },
    clickRowDelete(row) {
      this.current_planificacion = row
      this.dialogConfirmarBorradoPlanificacion = true
    },
    clickRowChecklist(item) {
      // this.$router.push({
      //   path: `/auditorias/tabs/checklist/${item.uuid}`,
      //   query: { auditoria_uuid: this.item.uuid },
      // })
    },
    confirmDeletePlanificacion(planificacion) {
      this.current_planificacion = planificacion
      this.dialogConfirmarBorradoPlanificacion = true
    },
    async removePlanificacion() {
      this.dialogConfirmarBorradoPlanificacion = false

      try {
        const xhr = await this.$store.dispatch(`auditorias/removePlanificacion`, {
          auditoria: this.item.uuid,
          planificacion: this.current_planificacion.uuid,
        })

        const res = await this.$store.dispatch('auditorias/get', this.item.uuid)
        if (res) this.item = this.auditoria = res
      } catch (e) {
        this.$store.commit('notification/show', {
          text: 'No se ha podido eliminar la planificación',
          color: 'error',
          timeout: 3000,
        })

        this.ui.showPlanificacionError = true
      }

      this.dialogConfirmarBorradoPlanificacion = false
    },

    async guardarCambiosAuditoria() {
      //return
      this.ui.loading = true
      // Format dates and hours
      let desde = this.$moment().format('YYYY-MM-DD')
      let hasta = this.$moment().format('YYYY-MM-DD')
      //let $desde = this.$moment().format('YYYY-MM-DD')

      this.item.desde = this.item.desde ? this.item.desde : null
      if (this.item.desde) {
        desde = this.$moment(this.item.desde).format('YYYY-MM-DD')
        this.item.desde = this.$moment(this.item.desde).format('YYYY-MM-DDTHH:mm:ss')
        if (this.item.desdeInicio) {
          this.item.desde = this.$moment(`${desde} ${this.item.desdeInicio}`).format(
            'YYYY-MM-DDTHH:mm:ss'
          )
        }
      }

      this.item.hasta = this.item.hasta ? this.item.hasta : null
      if (this.item.hasta) {
        hasta = this.$moment(this.item.hasta).format('YYYY-MM-DD')
        this.item.hasta = this.$moment(this.item.hasta).format('YYYY-MM-DDTHH:mm:ss')
        if (this.item.desdeFin) {
          this.item.hasta = this.$moment(`${hasta} ${this.item.desdeFin}`).format(
            'YYYY-MM-DDTHH:mm:ss'
          )
        }
      }

      if (!this.item.acompanantes) this.item.acompanantes = []
      if (this.item.acompanante_1 && this.item.cargo_acompanante_1) {
        this.item.acompanantes[0] = {
          acompanante: this.item.acompanante_1,
          cargo: this.item.cargo_acompanante_1,
        }
      }

      if (this.item.acompanante_2 && this.item.cargo_acompanante_2) {
        this.item.acompanantes[1] = {
          acompanante: this.item.acompanante_2,
          cargo: this.item.cargo_acompanante_2,
        }
      }

      if (
        this.item.representante &&
        this.item.representante.length == 0 &&
        Array.isArray(this.item.representante)
      )
        this.item.representante = {}
      if (this.item.representantenombre)
        this.item.representante.nombre = this.item.representantenombre
      if (this.item.emailRepresentante) this.item.representante.email = this.item.emailRepresentante
      if (this.item.nifRepresentante) this.item.representante.nif = this.item.nifRepresentante
      if (this.item.telefonoRepresentante)
        this.item.representante.telefono = this.item.telefonoRepresentante

      if (this.$refs.form.validate()) {
        await this.$store.dispatch(`auditorias/update`, this.item)
      }

      this.ui.loading = false
      this.$forceUpdate()
    },

    confirmarCambioEstado(estado) {
      // if (this.isCcl && estado == this.estado.CERRADA && !this.item.valoracion) {
      //   this.ui.showValoration = true
      //   return
      // }

      this.nuevo_estado = estado
      this.texto_confirmacion = this.auditoriaCerrada ? 'ABRIR AUDITORÍA' : 'CIERRE DE AUDITORÍA'

      if (!this.auditoriaCerrada)
        this.texto_confirmacion +=
          '<br> Se debe cerrar la auditoría cuando el checklist, pacs, y muestras estén realizados totalmente. No se podrá volver a abrir ¿Está seguro de cerrar la auditoría?'
      this.dialogConfirmarCambioEstado = true
      this.dialogIntroducirMotivo = false
    },

    async cambiarEstadoAuditoria(crearAuditoria) {
      let valid = true
      if (crearAuditoria) valid = this.$refs.createForm.validate()
      if (!!valid) {
        await this.$store.dispatch(`auditorias/cambiarEstado`, {
          uuid: this.item.uuid,
          estado: this.nuevo_estado,
        })
        if (this.nuevo_estado == 'CERRADA') {
          this.saveFinalState()
        }
        this.dialogConfirmarCambioEstado = false
        if (crearAuditoria) await this.createAuditoria()
        if (this.revision) this.$router.push({ path: `/revisiones` })
        else if (this.dictamen) this.$router.push({ path: `/dictamenes` })
        else this.$router.push({ path: `/auditorias` })
      }
    },
    async createAuditoria() {
      if (this.isNew) {
        let validForm = this.item.tipoVisita
        let emplazamientos = this.selected_productos.map((e) => e.emplazamiento)
        let emplazamientos_selected = this.selected_emplazamiento.map((e) => e.uuid)
        let emplazamientos_filtered = emplazamientos
          .flat()
          .filter((e) => emplazamientos_selected.includes(e.uuid))
          .map((e) => e.uuid)
        //make unique arrays, no repeat elements
        emplazamientos_filtered = [...new Set(emplazamientos_filtered)]
        emplazamientos_selected = [...new Set(emplazamientos_selected)]

        if (emplazamientos_filtered.length != emplazamientos_selected.length) {
          this.$store.commit('notification/show', {
            text: 'Debe seleccionar todos los emplazamientos de los productos seleccionados',
            color: 'warning lighten-2',
            timeout: 3000,
          })
          validForm = false
          return
        }
        const productos = this.selected_emplazamiento.map((e) => e.productos)
        const productos_selected = this.selected_productos.map((ps) => ps.uuid)
        const productos_filtered = productos
          .flat()
          .filter((p) => productos_selected.includes(p.uuid))
        if (productos_filtered.length != productos_selected.length) {
          this.$store.commit('notification/show', {
            text: 'Debe seleccionar el emplazamiento del producto seleccionado',
            color: 'warning lighten-2',
            timeout: 3000,
          })
          validForm = false
          return
        }

        if (
          (this.isNew && this.selected_productos.length == 0) ||
          this.selected_emplazamiento.length == 0
        ) {
          this.$store.commit('notification/show', {
            text: 'Debe seleccionar al menos un producto y un emplazamiento',
            color: 'warning lighten-2',
            timeout: 3000,
          })
          validForm = false
          return
        }

        if (validForm) {
          const body = {
            ...this.item,
          }

          //body.fecha_prevista = this.create_auditoria.fecha_prevista
          body.tipoVisita = this.item.tipoVisita
          body.cliente = '/api/usuarios/' + this.cliente.uuid
          body.alcance = '/api/alcances/' + body.subalcance.alcance.uuid
          body.subalcance = '/api/subalcances/' + body.subalcance.uuid
          body.auditores = body.auditores.map((e) => '/api/usuarios/' + e.uuid)
          body.emplazamientos = this.selected_emplazamiento.map((e) => e['@id'])
          body.productos = this.selected_productos.map((e) => e['@id'])
          body.responsable = '/api/usuarios/' + body.responsable
          body.expediente = '/api/expedientes/' + body.expediente.uuid
          body.acompanantes = [
            {
              acompanante: body.acompanante_1,
              cargo: body.cargo_acompanante_1,
            },
            {
              acompanante: body.acompanante_2,
              cargo: body.cargo_acompanante_2,
            },
          ]
          delete body.acompanante_1
          delete body.acompanante_2
          delete body.cargo_acompanante_1
          delete body.cargo_acompanante_2
          delete body.cargos
          delete body.sites
          delete body.tipo
          await this.$store.dispatch('auditorias/create', body)
          this.$router.push({ path: `/auditorias` })
        }
      } else {
        await this.$store.dispatch('auditorias/update', this.item)
        this.$router.push({ path: `/auditorias` })
      }
    },
    // PACS
    async getPACS() {
      if (this.item && this.item.uuid) {
        const res = await this.$store.dispatch('auditorias/pacs', this.item.uuid)
        if (res) this.pacs = res
      }
    },
    clickRowPACS(pac) {
      this.$router.push({
        path: `/auditorias/tabs/pacs/${pac.uuid}`,
        query: { auditoria_uuid: this.item.uuid },
      })
    },
    async getListadoNoConformidadesPendientesPac() {
      if (this.item && this.item.uuid) {
        const res = await this.$store.dispatch(
          'auditorias/noConformidadesPendientesPac',
          this.item.checklists.map((e) => e.uuid)
        )
        if (res) this.no_conformidades = res
      }
    },
    async crearPAC() {
      await this.$store.dispatch('auditorias/crearPac', {
        auditoria: this.item['@id'],
        no_conformidades: this.no_conformidades_seleccionadas,
      })
      this.no_conformidades_seleccionadas = []
      this.getPACS()
      this.getListadoNoConformidadesPendientesPac()
    },
    confirmarBorradoPAC(pac) {
      this.selected_pac = pac
      this.texto_confirmacion = '¿Seguro que desea eliminar el PAC seleccionado?'
      this.dialogConfirmarBorradoPAC = true
    },
    async deletePAC() {
      await this.$store.dispatch('auditorias/deletePac', {
        auditoria: this.item.uuid,
        pac: this.selected_pac.uuid,
      })
      this.dialogConfirmarBorradoPAC = false
      this.getPACS()
      this.getListadoNoConformidadesPendientesPac()
    },

    getNumPac(pac) {
      const nums = pac.noConformidades.map((pac) => pac.numero).join(',')
      return nums
    },

    getInfoNoConformidades(pac) {
      let info = ''
      for (let index = 0; index < pac.noConformidades.length; index++) {
        const no_conformidad = pac.noConformidades[index]
        if (info === '') info += no_conformidad.codigoCalculo
        else info += ', ' + no_conformidad.codigoCalculo
      }
      return info
    },
    // INFORME
    async loadFormatosData(plan_auditoria) {
      this.$store.commit('formatos/SET_MODULO', 'auditorias')
      this.$store.commit('formatos/SET_MODULO_REF', this.item.uuid)
      this.$store.commit('formatos/SET_ALCANCE', this.auditoria.alcance)
      this.$store.commit('formatos/SET_CLIENTE', this.auditoria.cliente)

      // Formato Tipos
      this.$store.commit('formatos/GET_TIPOS', this.$auth.user.empresa.codigo)

      // Formato Data
      const body = {
        ...this.item,
        checklists: this.auditoria_checklist,
        cliente: this.cliente,
      }

      // Solicitud
      const solicitud_uuid = this.iteracion.grupo_solicitud.uuid
      const solicitud = this.offlineMode
        ? await this.$store.dispatch('offlineMode/getSolicitud', solicitud_uuid)
        : await this.$store.dispatch('grupo_solicitudes/get', solicitud_uuid)
      if (solicitud) body.solicitud = solicitud

      // Instalacion
      if (this.cliente && solicitud) {
        const instalaciones = this.cliente.instalaciones
        let rgseaa = ''
        if (solicitud.params.alcance_producto && solicitud.params.alcance_producto.length) {
          rgseaa = solicitud.params.alcance_producto[0].rgseaa
        }
        for (let i = 0; i < instalaciones.length; i++) {
          const element = instalaciones[i]
          if (element.rgseaa === rgseaa) {
            body.instalacion = element
            break
          }
        }
      }

      // Productor
      const productor = this.item.sites.filter((element) => element.tipo === 'productor')
      if (productor.length) body.productor = productor[0].nombre

      // Planificaciones
      const emplazamientos = {}
      for (let i = 0; i < this.item.planificaciones.length; i++) {
        const element = this.item.planificaciones[i]
        const parent_uuid = element.site.parent_uuid
        const site_uuid = element.site.uuid
        const obj = {
          uuid: element.uuid,
          auditor: element.auditor.nombre,
          titulo_lista: element.plantilla_checklist?.titulo,
          emplazamiento: element.site.nombre,
          no_conformidades: [],
          no_conformidades_pendientes: [],
          site: {
            nombre: element.site.nombre,
            parent_uuid: parent_uuid,
            uuid: site_uuid,
          },
        }
        // if (!emplazamientos[parent_uuid]) emplazamientos[parent_uuid] = {}
        if (!emplazamientos[site_uuid]) emplazamientos[site_uuid] = {}
        // emplazamientos[parent_uuid].push(obj)
        emplazamientos[site_uuid] = obj
      }

      // PACS
      const no_conformidades = {}
      for (let i = 0; i < this.pacs.length; i++) {
        const pac_uuid = this.pacs[i].uuid
        const pac = await this.$store.dispatch('auditorias/pac', {
          auditoria: this.item.uuid,
          pac: pac_uuid,
        })

        // Acciones Correctivas PAC
        const no_conformidades_pac = {}
        const acciones_correctivas_pac = await this.$store.dispatch(
          'auditorias/getAccionesCorrectivasPAC',
          {
            auditoria: this.item.uuid,
            pac: pac_uuid,
          }
        )
        for (let k = 0; k < acciones_correctivas_pac.length; k++) {
          const accion_correctiva = acciones_correctivas_pac[k]
          const no_conformidades_accion = accion_correctiva.no_conformidades
          for (let n = 0; n < no_conformidades_accion.length; n++) {
            const no_conformidad_accion = no_conformidades_accion[n]
            const obj = {
              codigo: no_conformidad_accion.codigo,
              hint: no_conformidad_accion.hint,
              no_conformidad: no_conformidad_accion.no_conformidad,
              observaciones: no_conformidad_accion.observaciones,
              respuesta: no_conformidad_accion.respuesta,
              accion_correctiva: {
                responsable_implantacion: accion_correctiva.responsable_implantacion,
                fecha_implantacion: accion_correctiva.fecha_implantacion,
                created_at: accion_correctiva.created_at,
                causa: accion_correctiva.causa,
                accion_propuesta: accion_correctiva.accion_propuesta,
                evidencias_aportadas: accion_correctiva.evidencias_aportadas,
                valoracion_accion: accion_correctiva.valoracion_accion,
                valoracion_causa: accion_correctiva.valoracion_causa,
                valoracion_evidencias: accion_correctiva.valoracion_evidencias,
              },
            }
            no_conformidades_pac[no_conformidad_accion.uuid] = obj
          }
        }

        // No Conformidades PAC
        for (let k = 0; k < pac.no_conformidades.length; k++) {
          const no_conformidad = pac.no_conformidades[k]
          const emplazamiento = no_conformidad.site.uuid
          const obj = {
            ...no_conformidad,
            accion_correctiva: no_conformidades_pac[no_conformidad.uuid]
              ? no_conformidades_pac[no_conformidad.uuid].accion_correctiva
              : {},
          }
          emplazamientos[emplazamiento].no_conformidades.push(obj)
        }
      }

      // No Conformidades Pendientes
      for (let i = 0; i < this.no_conformidades.length; i++) {
        const no_conformidad_pendiente = this.no_conformidades[i]
        const emplazamiento = no_conformidad_pendiente.site.uuid
        emplazamientos[emplazamiento].no_conformidades_pendientes.push(no_conformidad_pendiente)
      }

      // Limpiando las no conformidades del body para agregar las que tienen accion correctiva
      body.no_conformidades = []

      // Datos por Instalacion
      const planificaciones = Object.values(emplazamientos)
      const instalaciones = {}
      for (let i = 0; i < planificaciones.length; i++) {
        const planificacion = planificaciones[i]
        const parent_uuid = planificacion.site.parent_uuid
        if (!instalaciones[parent_uuid]) {
          instalaciones[parent_uuid] = {
            ...body,
            planificaciones: [],
          }
        }
        instalaciones[parent_uuid].planificaciones.push(planificacion)
        // Añadimos las no conformidades con sus acciones correctivas
        instalaciones[parent_uuid].no_conformidades = instalaciones[
          parent_uuid
        ].no_conformidades.concat(planificacion.no_conformidades)
        instalaciones[parent_uuid].no_conformidades_pendientes = instalaciones[
          parent_uuid
        ].no_conformidades.concat(planificacion.no_conformidades_pendientes)
      }
      const datos = Object.values(instalaciones)
      if (!this.offlineMode) this.$store.commit('formatos/SET_DATOS', datos)
      // let route = '/formatos?plantilla='
      // route += plan_auditoria ? 'plan' : 'informe'
      // this.$router.push(route)

      // New v2 formatos load request
      $nuxt.$emit('formatos:load', {
        subalcance: this.auditoria.subalcance,
      })
    },

    async unlockAuditoria() {
      await this.$store.dispatch('auditorias/toggleLock', {
        uuid: this.item.uuid,
        action: 'unlock',
      })

      this.init()
    },

    //+-------------------------------------------------
    // getBloques()
    // Carga los bloques asociados a la auditoría
    // Si no hay, carga las plantillas de la auditoria
    // -----
    // Created on Mon Jul 05 2021
    //+-------------------------------------------------
    async getPlantillasBloques() {
      const uuid = this.uuid ? this.uuid : this.$route.params.uuid
      const xhr = await this.$store.dispatch('plantillas-bloques/list', {
        modulo: 'auditorias',
        subalcance: this.item.subalcance.uuid,
      })

      this.db.bloques = []
      if (xhr) this.db.bloques = xhr

      this.ui.refresh = Date.now()
    },

    async getBloques() {
      const uuid = this.uuid ? this.uuid : this.$route.params.uuid

      const xhr = await this.$store.dispatch('bloques/listByModuloUuid', {
        modulo: 'auditorias',
        auditoria: uuid,
      })

      if (xhr) {
        this.db.misBloques = []
        this.db.misBloques = xhr
        // for (const block of xhr) {
        //   block.isNew = true
        //   block.modulo_ref = uuid
        //   this.db.bloques[block.codigo] = block
        // }
      }
    },

    //+-------------------------------------------------
    // getBlocksForUUID()
    // Returns blocks based on uuid template
    // Otherwise return template
    // -----
    // Created on Wed Oct 27 2021
    //+-------------------------------------------------
    getBlocksForUUID(uuid) {
      let mine = this.db.misBloques.filter((block) => block.plantilla?.uuid == uuid)
      if (mine.length > 0) {
        return mine
      }

      //return this.db.bloques.filter((block) => block.uuid == uuid)
      return []
    },

    async updateBlock(key, block) {
      if (this.$refs['blck-' + key]) {
        await this.$refs['blck-' + key][0].doUpdate(block)
      }
      block.isNew = false
    },

    async deleteBlock(key, block) {
      if (this.$refs['blck-' + key]) {
        await this.$refs['blck-' + key][0].doReset(block)
      }

      block.isNew = false
      const uuid = this.uuid ? this.uuid : this.$route.params.uuid
      block.auditoria = '/api/auditorias/' + uuid
    },

    async resetBlock(key, block) {
      if (this.$refs['blck-' + key]) {
        await this.$refs['blck-' + key][0].doUpdateWithEmptyContent(block)
      }

      block.isNew = false
      const uuid = this.uuid ? this.uuid : this.$route.params.uuid
      block.auditoria = '/api/auditorias/' + uuid
    },

    cloneBlock(key, block) {
      if (this.$refs['blck-' + key]) this.$refs['blck-' + key][0].doClone(block)
    },

    goToClienteFicha() {
      this.$router.push(`/personal/${this.cliente.uuid}?type=cliente`)
    },

    // async getAuditores() {
    //   const xhr = await this.$store.dispatch('auditores/list')
    //   this.db.auditores = [...xhr]
    // },

    async checkRoles() {
      this.auth = await this.$store.dispatch('user/can', {
        module: 'auditorias',
        return: 'all',
      })

      this.auth.checklists = await this.$store.dispatch('user/can', {
        module: 'checklists',
        // submodule: 'checklists',
        return: 'all',
      })

      this.auth.revisiones = await this.$store.dispatch('user/can', {
        module: 'revisiones',
        return: 'all',
      })
      this.auth.pacs = {
        canList: this.auth.canListPacs,
        canCreate: this.auth.canCreatePacs,
        canEdit: this.auth.canEditPacs,
        canDelete: this.auth.canDeletePacs,
      }
    },

    async addBlocks() {
      const uuid = this.uuid ? this.uuid : this.$route.params.uuid
      const params = {
        uuid: uuid,
        bloques: this.blockSelected,
      }
      const xhr = await this.$store.dispatch('auditorias/addBloques', params)
      this.ui.dialogBloques = false
      if (xhr) this.db.misBloques = xhr
      await this.getBloques()
    },

    //+-------------------------------------------------
    // getSnapshot()
    // Gets snapshotted data
    // -----
    // Created on Tue Mar 08 2022
    //+-------------------------------------------------
    async getSnapshot() {
      if (!this.$nuxt.isOffline) return

      const xhr = await this.$store.dispatch('offline/getSnapshot', this.$route.path)
      if (xhr) {
        for (const key in xhr) {
          this[key] = xhr[key]
        }
      }
    },

    async init() {
      await this.checkRoles()

      await this.getOne().then(() => {
        this.getEstaticos()
        this.item.tipoVisita = this.item.tipoVisita ? this.item.tipoVisita : ['Auditoria Inicial']
      })
      // await this.checkOffline() -- removed for new offline
      //await this.getSnapshot()
      let params = this.$route.fullPath.split('?tab=')
      if (params[1]) this.tab = parseInt(params[1])
      // Con los datos de la query controlamos si venimos de una revisión o de un dictamen
      if (this.$route.query.revision) this.revision = true
      if (this.$route.query.dictamen) this.dictamen = true
      if (!this.consulta) this.setTabUrl()

      /* if (this.isCcl && this.item.revisor) {
        this.item.planificaciones.forEach((planificacion) => {
          if (this.item.revisor.uuid === planificacion.auditor.uuid) {
            planificacion.type = 'Revisor'
          }
        })
      } */

      //$nuxt.$emit('offline:loaded', {
      //  item: 'auditoria',
      //  uuid: this.uuid,
      //  data: this.item,
      //})

      // if offfline
      // if cache this url
      // replace this[key] = cache.data
    },
  },

  async created() {
    this.ui.loading = true
    await this.init()
    if (!this.item.desde) this.item.desde = this.$moment().format('YYYY-MM-DD')
    if (!this.item.hasta) this.item.hasta = this.$moment().format('YYYY-MM-DD')
    if (this.item.emitidoPor) this.item.emitidoPor = this.item.emitidoPor['@id']
    this.ui.loading = false

    this.$nuxt.$on('bloques:refresh', (payload) => {
      this.getBloques()
    })
    this.$nuxt.$emit('formatos:load', {
      subalcance: this.auditoria.subalcance,
    })

    /* this.$nuxt.$on('offline:snapshot', (payload) => {
      this.$store.commit('offline/snapshotPage', {
        item: this.item,
      })
    }) */
  },

  beforeDestroy() {
    /* this.$nuxt.$off('bloques:refresh')*/
    //this.$nuxt.$off('offline:snapshot')
  },
}
</script>

<style>
.estado-auditoria {
  position: absolute;
  min-width: 120px !important;
  right: 15px;
  top: 10px;
}

.estado-auditoria-left {
  position: absolute;
  min-width: 120px !important;
  right: 155px;
  top: 10px;
}

.theme--light.v-data-table.auditoria tbody tr:hover:not(.v-data-table__expand-row) {
  background: #eeeeee;
  cursor: context-menu !important;
}

.isLocked * {
  opacity: 0.95;
  pointer-events: none;
  cursor: pointer;
}
</style>
