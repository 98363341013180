import menu from '~/models/menu'
import menuClients from '~/models/menuClients'
import moduloToLink from '~/models/moduloToLink';
import ROLE from '~/models/role'

export default async function (ctx) {
  const whiteListLinks = [
    'login',
    'logout',
    'dashboard',
    'formatos',
    // 'generador_formatos',
    'informes',
    'errors',
    'marcas',
    'profile'
  ]

  const getLinkFromModulo = (moduleName) => moduloToLink[moduleName];

  if (ctx.$auth.user) {
    // let menu = ctx.store.getters['layout/menu']
    // if (menu.length == 0) menu = await ctx.store.dispatch('layout/menu')
    const path = ctx.route.path.split('/')
    const route = ctx.route.name.split('-')
    const roles = ctx.$auth.user.roles || []
    const permisos = ctx.$auth.user.permisos || []

    let isPortal = ctx.route.path.indexOf('portal/') > -1 ? true : false
    let routePath = ctx.route.path.replace('/', '')
    let routePath_ = path[1]
    let routeName = route[0]

    // console.warn(routePath, routePath_, routeName, ctx.route)

    //+-------------------------------------------------
    // Roles and permissions disabled for clients and tecnicos
    // that want to access the portal pages
    //+-------------------------------------------------
    if (ctx.$auth.user.tipo == 'cliente' || ctx.$auth.user.tipo == 'tecnico') {
      if (isPortal) return
      else if (!whiteListLinks.indexOf(routeName)) return ctx.redirect('/dashboard')
    }

    // if the current routeName is in the whiteListLinks
    // then we don't need to check the roles and permissions
    if (whiteListLinks.indexOf(routeName) > -1) {
      return
    }

    // let theMenu = isPortal ? menuClients.concat(menu) : menu
    // // console.warn(isPortal, theMenu)

    // // get the menu item where modulo is routePath
    // // or submodulo in any of its items children is routepath
    // let subItem = null
    // let menuItem = theMenu.find((item) => {
    //   if (
    //     // item.modulo == routeName ||
    //     item.modulo == routePath_ ||
    //     item.link == routePath ||
    //     item.link == routePath_
    //   ) {
    //     // console.warn('menuItem', item, routePath, routePath_, routeName)
    //     return item
    //   } else if (item.items) {
    //     let submenuItem = item.items.find((subitem) => {
    //       if (subitem.link == routeName) {
    //         // debugger
    //         // console.warn('🎊', 'encontrado item.link = a nombre de ruta', subitem.link, routeName)
    //         return subitem
    //       }
    //       if (subitem.submodulo == routeName) {
    //         return subitem
    //       }
    //     })
    //     if (submenuItem) {
    //       subItem = submenuItem
    //       return submenuItem
    //     }
    //   }
    // })
    
    if (routePath_ == 'grupo_solicitudes') routePath_ = 'solicitudes'
    if (routePath.indexOf('personal/') == 0) routePath_ = 'personal/usuarios'
    //si la ruta contiene /admin/ entonces se toma el primer segmento despues de /admin/ como el modulo
    if (routePath.indexOf('admin/') == 0) routePath_ = routePath.split('/')[1]

    //si la ruta es marcas entonces se toma el modulo como alcances
    if (routePath.indexOf('marcas') > -1) routePath_ = 'alcances'


    let menuItem = permisos.find((item) => {
      // console.log('ITEM',item.modulo)
      // console.log('ROUTE',routePath_)
      
      if (routePath.indexOf('grupo_solicitudes') > -1) {
        if (item.modulo == 'solicitudes' && item.submodulo == 'solicitudes') return item
      }
      if (getLinkFromModulo(item.modulo) !== null){
        if (
          (getLinkFromModulo(item.modulo) == routePath || getLinkFromModulo(item.modulo) == routePath_ || getLinkFromModulo(item.modulo).indexOf(routePath_) > -1) &&
          item.canConfig?.canList == true
          //   // item.modulo == routeName ||
          //   // item.modulo == routePath_ ||
          //   // item.submodulo == routeName
        )
        return item
      }
    })


    // if (!menuItem) {
    //   let menuFlat = menu.reduce((acc, item) => {
    //     acc.push(item)
    //     if (item.items.length) {
    //       item.items.forEach((subitem) => {
    //         acc.push(subitem)
    //       })
    //     }

    //     return acc
    //   }, [])

    //   console.warn('buscando en menu', menuFlat, routeName, routePath)

    //   menuItem = menuFlat.find((item) => {
    //     if (
    //       item.modulo == routeName ||
    //       item.modulo == routePath ||
    //       item.submodulo == routeName ||
    //       item.link == routeName
    //     ) {
    //       return item
    //     }
    //   })
    // }

    // console.error('✨', menuItem)
    // debugger

    if (!menuItem) {
      ctx.$auth.user.unauthorized = { module: routePath_ }
      console.warn(
        '🔐',
        'No se encuentra permiso para el modulo',
        { module: routePath_ },
        roles,
        ctx.route.path
      )

      if (roles.indexOf('ROLE_ADMIN') >= 0) {
        return
      }
      if (roles.indexOf('ROLE_ADMIN_GROUP') >= 0) {
        return
      } else {
        console.warn('🔐', roles, roles.indexOf('ROLE_ADMIN'))
        // console.warn('menuItem', item, routePath, routePath_, routeName)
        return ctx.redirect('/errors/unauthorized')
      }
    }

    let roleToCheck = menuItem.rol
    let moduloToCheck = menuItem.modulo

    if (roles.indexOf(roleToCheck) < 0) {
      ctx.$auth.user.unauthorized = { module: moduloToCheck }
      console.warn('🔐', 'No se permite el acceso', `No tiene el rol mínimo ${roleToCheck}`, roles)
      return ctx.redirect('/errors/unauthorized')
    }

    if (menuItem.canConfig?.canList !== true) {
      let someTrue = Object.values(menuItem.canConfig).some((item) => item == true)

      if (!someTrue) {
        ctx.$auth.user.unauthorized = { module: moduloToCheck }
        console.warn(
          '🔐',
          'No se permite el acceso',
          `No tiene permiso para listar ${moduloToCheck}`,
          menuItem
        )
        return ctx.redirect('/errors/unauthorized')
      }
    }

    // User is allowed
    ctx.store.commit('permisos/SET_PERMISOS', {
      can_list: menuItem.canConfig?.canList || false,
      can_create: menuItem.canConfig?.canCreate || false,
      can_edit: menuItem.canConfig?.canEdit || false,
      can_delete: menuItem.canConfig?.canDelete || false,
      ...menuItem.canConfig,
    })
  }
}
