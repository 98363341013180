<template>
  <v-card class="px-4">
    <floating-button-menu :valid="!existChanges" @submit="saveChanges"></floating-button-menu>

    <loading v-model="$store.getters.loading" text="Espere por favor ..." />
    <loading v-model="ui.loading" text="Espere por favor ..." />

    <!-- OVERLAY -->
    <!-- <v-overlay :value="overlay" class="text-center">
      <h4>Expediente desactivado
        <v-icon>mdi-alert-octagon</v-icon>
      </h4>
      <br>
      <v-btn color="success" @click="updateActivarDesactivar(true)">
        ACTIVAR EXPEDIENTE
      </v-btn>

    </v-overlay> -->

    <!-- <v-divider></v-divider> -->
    <v-row>
      <v-expand-transition>
        <v-col cols="12" class="pt-4 text-right" v-if="!existChanges && noChanges">
          <span
            class="px-3 py-2 warning--text"
            style="background-color: #f7e5e6; border-radius: 4px">
            <v-icon color="warning" class="mr-4 pb-1">mdi-alert</v-icon>
            No existen cambios a guardar
          </span>
        </v-col>
      </v-expand-transition>

      <v-col cols="12">
        <v-card-title class="text-h5 pa-0">
          Expediente
          {{
            item.cliente && item.cliente.nombreCompleto ? ' de ' + item.cliente.nombreCompleto : ''
          }}
          <!-- state chip -->
          <v-chip
            v-if="item.estado"
            :color="item.estado === 'Activo' ? 'success' : 'error'"
            class="ml-2"
            text
            small
            @click="updateActivarDesactivar(item.estado === 'Activo' ? false : true)"
            outlined>
            {{ item.estado }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-subheader class="">Este expediente pertenece a la marca</v-subheader>
          <v-chip v-if="item.subalcance" :color="item.subalcance.color" class="ml-1" outlined>
            {{ item.subalcance.nombre }}
          </v-chip>
        </v-card-title>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-subheader class="pl-0 info-expediente">Código</v-subheader>
        <v-text-field
          v-model="item.codigo"
          dense
          outlined
          hide-details
          @change="updateCodigo()"
          :disabled="isDisabled"></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-subheader class="pl-0 info-expediente">Fecha de inicio</v-subheader>
        <v-text-field :value="formatDate(item.fecha)" dense outlined hide-details></v-text-field>
      </v-col>

      <v-col cols="2">
        <v-subheader class="pl-0 info-expediente">Fecha de entrada en el sistema</v-subheader>
        <v-text-field
          :value="formatDate(item.createdAt)"
          dense
          outlined
          hide-details
          disabled></v-text-field>
      </v-col>

      <v-col cols="2">
        <v-subheader class="pl-0 info-expediente">Última actualización</v-subheader>

        <v-text-field
          :value="formatDate(item.updatedAt)"
          dense
          outlined
          hide-details
          disabled></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-subheader class="pl-0 info-expediente">Número de expediente</v-subheader>
        <v-text-field
          v-model="item.numeroExpediente"
          dense
          outlined
          hide-details
          :disabled="isDisabled"></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-subheader class="pl-0 info-expediente">Número de Registro</v-subheader>
        <v-text-field
          v-model="item.numeroRegistro"
          dense
          outlined
          hide-details
          :disabled="isDisabled"></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-subheader class="pl-0 info-expediente">Auditoria bloqueada hasta</v-subheader>
        <div>
          <v-menu :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on }">
              <!-- <h4 class="text-capitalize" v-text="formatDate(iteracionSelected.bloqueado_hasta)" v-on="on"></h4> -->
              <v-text-field
                :value="formatDate(iteracionSelected.bloqueado_hasta)"
                readonly
                dense
                outlined
                hide-details
                v-on="on"
                :disabled="isDisabled"></v-text-field>
            </template>
            <v-date-picker
              v-model="iteracionSelected.bloqueado_hasta"
              color="primary"
              :first-day-of-week="1"
              @change="updateFechasIteracion()"></v-date-picker>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="2">
        <v-subheader class="pl-0 info-expediente"
          >Código de contrato
        </v-subheader>
        <v-text-field
          v-model="iteracionSelected.codigoContrato"
          dense
          outlined
          hide-details
          disabled>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="showAnexoContrato ? 6 : 12">
        <v-subheader class="pl-0 info-expediente">Observaciones</v-subheader>
        <v-textarea dense outlined v-model="item.descripcion"></v-textarea>
      </v-col>
      <v-col v-show="showAnexoContrato" cols="6">
        <v-subheader class="pl-0 info-expediente"
          >Anexo Contrato
        </v-subheader>
        <v-textarea dense outlined v-model="iteracionSelected.anexoContrato"> </v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-card class="ma-2">
          <v-card-title class="pa-2">
            <v-icon class="mr-2" color="teal">mdi-ballot-outline</v-icon>
            <span>Última Auditoría</span>
          </v-card-title>
          <v-card-subtitle class="mt-1">
            Consulta los datos de la última auditoria
          </v-card-subtitle>
          <v-card-actions>
            <v-btn
              @click="goToLastAuditoria"
              color="primary lighten-2"
              text
              :disabled="!this.item.lastAuditoria">
              Ver
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn icon @click="ui.showAuditoriaExpand = !ui.showAuditoriaExpand">
              <v-icon>{{ ui.showAuditoriaExpand ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="ui.showAuditoriaExpand" class="expandable-content">
              <v-divider></v-divider>
              <v-card-text
                v-if="item.lastAuditoria"
                style="background-color: #ececec; border: 1px solid #ddd">
                <v-row v-if="item && item.lastAuditoria">
                  <v-col cols="6">
                    <h4>Fecha:</h4>
                  </v-col>
                  <v-col cols="6">
                    <h5>{{ fechaLastAuditoria }}</h5>
                  </v-col>
                </v-row>
                <v-row v-if="item.lastAuditoria">
                  <v-col cols="6">
                    <h5>Auditores:</h5>
                  </v-col>
                  <v-col cols="6">
                    <template v-for="auditor in item.lastAuditoria.auditores">
                      <h5>{{ auditor }}</h5>
                    </template>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <h5>Asignado Checklist:</h5>
                  </v-col>
                  <v-col cols="6">
                    <h5>
                      {{ canChecklistAsignado }}
                    </h5>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <h5>Num No Conformidades :</h5>
                  </v-col>
                  <v-col cols="6">
                    <h5>{{ item.lastAuditoria.nNoConformidades }}</h5>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <h5>Tomas de Muestras:</h5>
                  </v-col>
                  <v-col cols="6">
                    <h5>{{ item.lastAuditoria.tomaMuestras }}</h5>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="ma-2">
          <v-card-title class="pa-2">
            <v-icon class="mr-2" color="purple lighten-3"
              >mdi-checkbox-multiple-marked-outline</v-icon
            >
            <span>Última Revisión</span>
          </v-card-title>
          <v-card-subtitle class="mt-1"> Consulta los datos de la última revisión </v-card-subtitle>
          <v-card-actions>
            <v-btn
              @click="goToLastRevision"
              color="primary lighten-2"
              text
              :disabled="!this.item.lastRevision">
              Ver
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn icon @click="ui.showRevisionExpand = !ui.showRevisionExpand">
              <v-icon>{{ ui.showRevisionExpand ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="ui.showRevisionExpand" class="expandable-content">
              <v-divider></v-divider>
              <v-card-text
                v-if="item.lastRevision"
                style="background-color: #ececec; border: 1px solid #ddd; border: 1px solid #ddd">
                <v-row>
                  <v-col cols="6">
                    <h4>Fecha:</h4>
                  </v-col>
                  <v-col cols="6">
                    <h4></h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <h4>Revisor:</h4>
                  </v-col>
                  <v-col cols="6">
                    <h4></h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <h4>Proposicion:</h4>
                  </v-col>
                  <v-col cols="6">
                    <h4></h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <h4>Observaciones:</h4>
                  </v-col>
                  <v-col cols="6">
                    <h4></h4>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="ma-2">
          <v-card-title class="pa-2">
            <v-icon class="mr-2" color="indigo accent-2">mdi-gavel</v-icon>
            <span>Último Dictamen</span>
          </v-card-title>
          <v-card-subtitle class="mt-1"> Consulta los datos del último dictamen </v-card-subtitle>
          <v-card-actions>
            <v-btn
              @click="goToLastDictamen"
              color="primary lighten-2"
              text
              :disabled="!this.item.lastDictamen">
              Ver
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn icon @click="ui.showDictamenExpand = !ui.showDictamenExpand">
              <v-icon>{{ ui.showDictamenExpand ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="ui.showDictamenExpand" class="expandable-content">
              <v-divider></v-divider>
              <v-card-text
                v-if="item.lastDictamen"
                style="background-color: #ececec; border: 1px solid #ddd">
                <v-row>
                  <v-col cols="6">
                    <h4>Fecha:</h4>
                  </v-col>
                  <v-col cols="6">
                    <h4></h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <h4>Persona:</h4>
                  </v-col>
                  <v-col cols="6">
                    <h4></h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <h4>En calidad de:</h4>
                  </v-col>
                  <v-col cols="6">
                    <h4></h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <h4>Resultado:</h4>
                  </v-col>
                  <v-col cols="6">
                    <h4></h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <h4>Acción:</h4>
                  </v-col>
                  <v-col cols="6">
                    <h4></h4>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="ma-2">
          <v-card-title class="pa-2">
            <v-icon class="mr-2" color="light-green darken-4">mdi-certificate</v-icon>
            <span>Último Certificado</span>
          </v-card-title>
          <v-card-subtitle class="mt-1">
            Consulta los datos del último certificado
          </v-card-subtitle>
          <v-card-actions>
            <v-btn
              @click="goToLastCertificado"
              color="primary lighten-2"
              text
              :disabled="!this.item.lastCertificado">
              Ver
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn icon @click="ui.showCertificadoExpand = !ui.showCertificadoExpand">
              <v-icon>{{
                ui.showCertificadoExpand ? 'mdi-chevron-up' : 'mdi-chevron-down'
              }}</v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="ui.showCertificadoExpand" class="expandable-content">
              <v-divider></v-divider>
              <v-card-text
                v-if="item.lastCertificado"
                style="background-color: #ececec; border: 1px solid #ddd">
                <v-row>
                  <v-col cols="6">
                    <h4>Fecha de expedición:</h4>
                  </v-col>
                  <v-col cols="6">
                    <h4></h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <h4>Válido desde:</h4>
                  </v-col>
                  <v-col cols="6">
                    <h4></h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <h4>Válido hasta:</h4>
                  </v-col>
                  <v-col cols="6">
                    <h4></h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <h4>Observaciones:</h4>
                  </v-col>
                  <v-col cols="6">
                    <h4></h4>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>

    <v-divider class="my-2"></v-divider>
    <v-row
      v-if="(auth.solicitudes && auth.solicitudes.canList) || true"
      align="center"
      v-show="tab !== 'comunicaciones' && tab !== 'historico' && tab !== 'marcas'">
      <!-- Year -->
      <v-col cols="2" xl="1" class="text-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" small text depressed v-on="on" class="mt-2 mb-2">
              Año: {{ iteracionYearSelected }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group mandatory v-model="iteracionYearSelected">
              <v-list-item
                v-for="year in iteracionYears"
                :key="year"
                :value="year"
                class="mt-2 mb-2">
                <v-list-item-title>{{ year }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>

      <!-- Iteraciones -->
      <v-col cols="10" xl="11">
        <template v-for="year in iteracionYears">
          <v-slide-group show-arrows :key="year" v-if="year === iteracionYearSelected">
            <v-slide-item v-for="(iteracion, i) in item.iteraciones" :key="i" class="mr-5">
              <v-btn
                v-if="iteracionMatchYear(i, year)"
                v-model="iteracionSelected"
                small
                text
                @click="updateDatosIteracion(iteracion)">
                <v-icon small class="mr-2">mdi-calendar</v-icon>
                Iteracion del {{ iteracion.codigo }}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
        </template>
      </v-col>
    </v-row>
    <v-divider class="my-2"></v-divider>
    <v-row>
      <v-col cols="12">
        <v-select
          placeholder="Selecciona un modulo para interactuar con el"
          v-model="tab"
          :items="[
            'Productos',
            'Auditorias',
            'Solicitudes',
            'Revisiones',
            'Dictamenes',
            'Tomas de Muestras',
            'Checklists',
            'Pacs',
          ]"
          item-text="nombre"
          item-value="nombre"
          :disabled="isDisabled"
          outlined
          dense></v-select>
      </v-col>
    </v-row>

    <v-tabs-items v-model="tab">
      <v-row v-show="tab === 'Auditorias'">
        <v-col cols="12">
          <auditorias-listado
            :iteracion="iteracionAll ? {} : iteracionSelected"
            :expediente="item.uuid"
            :cliente="item.cliente"
            :subalcance="item.subalcance"
            v-if="tab === 'Auditorias' && item && iteracionSelected" />
        </v-col>
      </v-row>

      <v-tab-item :value="'Productos'">
        <v-row>
          <v-col cols="12">
            <SelectEmplazamientoProductos
              :emplazamientos="iteracionSelected.emplazamientos"
              :productos="iteracionSelected.productos"
              :subalcance="item.subalcance"
              @updateSelectedEmplazamientos="updateSelectedEmplazamientos($event)"
              @updateSelectedProductos="updateSelectedProductos($event)" />
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item :value="'Solicitudes'">
        <solicitudes-listado
          :iteracion="iteracionAll ? {} : iteracionSelected"
          :uuid="item.uuid"
          :expediente="item.uuid"
          v-if="tab === 'Solicitudes' && item && iteracionSelected" />
      </v-tab-item>

      <v-tab-item :value="'Revisiones'">
        <revisiones-listado
          :iteracion="iteracionAll ? {} : iteracionSelected"
          :uuid="item.uuid"
          v-if="tab === 'Revisiones' && item && iteracionSelected" />
      </v-tab-item>

      <v-tab-item :value="'Dictamenes'">
        <dictamenes-listado
          :iteracion="iteracionAll ? {} : iteracionSelected"
          :uuid="item.uuid"
          v-if="tab === 'Dictamenes' && item && iteracionSelected" />
      </v-tab-item>

      <v-tab-item :value="'Tomas de Muestras'">
        <toma-muestras-listado
          :iteracion="iteracionAll ? {} : iteracionSelected"
          :expediente="item.uuid"
          v-if="tab === 'Tomas de Muestras' && item && iteracionSelected" />
      </v-tab-item>

      <v-tab-item :value="'Checklists'">
        <checklist-listado
          :iteracion="iteracionSelected"
          :expediente="item"
          :iteracionSelected="iteracionSelected"
          v-if="tab === 'Checklists' && item && iteracionSelected" />
      </v-tab-item>

      <v-tab-item :value="'Pacs'" class="ma-3 pa-3">
        <pacs-listado
          :iteracion="iteracionSelected"
          :expediente="item"
          :iteracionSelected="iteracionSelected"
          v-if="tab === 'Pacs' && item && iteracionSelected" />
      </v-tab-item>

      <!-- <v-tab-item :value="'historico'">
        <template v-if="item && item.alcance">
          <bloques ref="bloques" :alcance="item.alcance.uuid" :modulo="'expedientes'" :modulo_ref="item.uuid">
          </bloques>
        </template>
      </v-tab-item> -->
    </v-tabs-items>

    <!-- Actions Modal -->
    <!-- <actions-modal v-model="dialog" :item="action" :rowIndex="rowIndex" @close="closeAction()"
      @changes="actionChanges($event)" @discard="getOne()" @save="saveAction()" /> -->

    <!-- Create Auditoria Dialog Modal -->
    <create-auditoria-modal
      v-if="item.cliente"
      v-model="dialogVisitaAuditoria"
      :cliente="item.cliente"
      :iteracion="iteracionSelected"
      :bloqueado_hasta="iteracionSelected.bloqueado_hasta"
      :alcance="item.alcance"
      :sites="iteracionSelected.emplazamientos"
      :gridOptions="gridOptions"
      @close="dialogVisitaAuditoria = false"
      @save-auditoria="saveAuditoria($event)" />
  </v-card>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    expedientes/default.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Tue Jul 25 2023
 **/

import format from '~/util/formatHelper'

import AgDatatableSelectable from '~/components/AgDatatableSelectable'
import Loading from '~/components/Loading'
import Confirmation from '~/components/Confirmation'
import COLORS from '~/models/colors'
// Forms
import SelectAlcance from '~/components/forms/SelectAlcances'
import SelectClientes from '~/components/forms/SelectClientes'
import SelectProvincia from '~/components/forms/SelectProvincia'
import FloatingButtonMenu from '~/components/FloatingButtonMenu'
//
import ExpedienteVariables from '~/components/customVariables/CustomVariables'
import ActionsModal from '~/components/expedientes/ActionsModal'
import CreateAuditoriaModal from '~/components/auditorias/CreateAuditoriaModal'

// Listados
import MarcasListado from '~/pages/marcas/index'
import AuditoriasListado from '~/components/expedientes/widgets/auditorias'
import SolicitudesListado from '~/components/expedientes/widgets/solicitudes'
import RevisionesListado from '~/components/expedientes/widgets/revisiones'
import DictamenesListado from '~/components/expedientes/widgets/dictamenes'
import TomaMuestrasListado from '~/components/expedientes/widgets/toma_muestras'
import PacsListado from '~/components/expedientes/widgets/pacs'

// Actions Multiple
import Sites from '~/components/solicitudes/sites'
// import ParcelasActionsMultiple from "~/components/expedientes/coplaca/ExpedienteCoplacaParcelasActionsMultiple";
import ExpedienteSites from '~/components/expedientes/sites'

import bloques from '~/components/bloques/section'

import SelectEmplazamientoProductos from '../auditorias/SelectEmplazamientoProductos.vue'
import { f } from 'dropzone'

export default {
  components: {
    ExpedienteSites,
    Sites,
    bloques,
    CreateAuditoriaModal,
    Confirmation,
    Loading,
    AgDatatableSelectable,
    SelectAlcance,
    SelectClientes,
    SelectProvincia,
    FloatingButtonMenu,
    ExpedienteVariables,
    ActionsModal,
    MarcasListado,
    AuditoriasListado,
    SolicitudesListado,
    RevisionesListado,
    DictamenesListado,
    TomaMuestrasListado,
    PacsListado,
    SelectEmplazamientoProductos,
  },
  data: () => ({
    component: 0,
    dialog: false,
    noChanges: false,
    changesGrid: false,
    search: '',
    emplazamiento: null,
    productos: [],
    tabProductos: '',
    colors: COLORS,
    grids: {},
    selected_emplazamiento: [],
    selected_productos: [],
    action: {
      action: '',
      selection: [],
    },

    actions: [
      // {
      //   tipo: "productor", action: "ExpedienteCoplacaProductoresActionsCellRenderer"
      // },
      // { tipo: "parcela", action: "ExpedienteCoplacaParcelasActionsCellRenderer", multiple: ParcelasActionsMultiple }
    ],
    editable_headers: [
      // { tipo: "productor", headers: ["nombre_productor", "cambio_titular"] },
      // { tipo: "parcela", headers: ["cod_provincia", "cod_municipio", "cambio_titular"] }
    ],
    gridOptions: {
      // productor : {
      //   getRowStyle: params => {
      //     if (params.data.cambio_titular) return { background: '#dfdfff' }
      //   }
      // },
      // parcela : {
      //   getRowStyle: params => {
      //     if (params.data.cambio_titular) return { background: '#dfdfff' }
      //   }
      // }
    },
    //
    overlay: false,
    dialogVisitaAuditoria: false,
    rowIndex: [],
    globalgapActions: [
      // {name: 'Baja', action: 'baja', tipo_fichero: ['CENTRALES', 'PRODUCTORES']},
      // {name: 'Cambio superficie', action: 'cambio_superficie', tipo_fichero: ['PRODUCTOS']},
      // {name: 'Cambio cultivo', action: 'cambio_cultivo', tipo_fichero: ['PRODUCTOS']},
      // {name: 'Cambio GGN', action: 'cambio_ggn', tipo_fichero: ['PRODUCTORES']},
      // {name: 'Cambio GLN', action: 'cambio_gln', tipo_fichero: ['PRODUCTORES']},
      // {name: 'Cambio NIF', action: 'cambio_nif', tipo_fichero: ['PRODUCTORES']},
      // {name: 'Eliminar', action: 'eliminar', tipo_fichero: ['PRODUCTORES', 'PRODUCTOS']}
    ],
    item: {
      iteraciones: [],
      grupo_alcance: {},
      lastAuditoria: {},
      lastRevision: {},
      lastDictamen: {},
      lastCertificado: {},
    },
    iteracionYears: [],
    iteracionYearSelected: {},
    iteracionSelected: {},
    iteracionAll: false,

    auth: {
      auditorias: {},
      revisiones: {},
      dictamenes: {},
      solicitudes: {},
      expedientes: {},
      certificados: {},
    },

    ui: {
      f: format,
      showPicker: false,
      loading: false,
      showAuditoriaExpand: false,
      showRevisionExpand: false,
      showDictamenExpand: false,
      showCertificadoExpand: false,
      selectProductos: true,
    },
  }),
  beforeMount() {
    this.frameworkComponents = {
      BooleanCellEditor: 'BooleanCellEditor',
      AutocompleteCellEditor: 'AutocompleteCellEditor',
      SelectCellEditor: 'SelectCellEditor',
      TextCellEditor: 'TextCellEditor',
      DateCellEditor: 'DateCellEditor',
    }
    this.excelStyles = [
      {
        id: 'default',
        dataType: 'string',
      },
    ]
  },

  watch: {
    tab(val) {
      if (val === 'solicitud') this.getOne()
    },
  },

  computed: {
    isDisabled() {
      if (this.auth?.expedientes?.canEdit) return false
      return true
    },
    showAnexoContrato() {
      return this.$auth?.user?.empresa?.configuracion?.showAnexoContrato
    },

    isQuesoManchego() {
      return this.item?.alcance?.codigo == 'queso-manchego'
    },

    tab: {
      get() {
        return this.$store.state.expedientes.tab
      },
      set(val) {
        this.$store.commit('expedientes/SET_TAB', val)
      },
    },

    tabs() {
      let tabs = [
        'solicitud',
        'auditorias',
        'revisiones',
        'dictamenes',
        'certificados',
        'comunicaciones',
        'historico',
      ]

      if (this.isQuesoManchego) {
        tabs.push('marcas')
      }

      if (!this.auth.auditorias.canList) tabs.splice(tabs.indexOf('auditorias'), 1)
      if (!this.auth.revisiones.canList) tabs.splice(tabs.indexOf('revisiones'), 1)
      if (!this.auth.dictamenes.canList) tabs.splice(tabs.indexOf('dictamenes'), 1)
      if (!this.auth.certificados.canList) tabs.splice(tabs.indexOf('certificados'), 1)

      return tabs
    },

    changesTable() {
      return this.$store.state.expedientes.changesTable
    },

    existChanges() {
      return false
    },

    currentColor() {
      return this.colors[2]
    },

    currentSolicitud() {
      let solicitud = {}
      if (this.iteracionSelected?.grupo_solicitud?.solicitudes) {
        if (this.iteracionSelected?.grupo_solicitud?.solicitudes[0].data) {
          solicitud = this.iteracionSelected?.grupo_solicitud?.solicitudes[0].data
        }
      }
      return solicitud
    },

    fechaLastAuditoria() {
      if (this.item.lastAuditoria) {
        return this.$moment(this.item.lastAuditoria.fecha).format('DD-MM-YYYY')
      }
      return ''
    },
    canChecklistAsignado() {
      if (this.item.lastAuditoria && this.item.lastAuditoria.checklists) {
        return this.item.lastAuditoria.checklists.length > 0 ? 'Si' : 'No'
      }
      return 'No'
    },
  },
  methods: {
    updateSelectedProductos(productos) {
      this.selected_productos = productos
    },
    updateSelectedEmplazamientos(emplazamientos) {
      this.selected_emplazamiento = emplazamientos
    },
    checkClids() {
      this.$forceUpdate()
      this.ui.loading = true
      //create sleep of 3 seconds
      setTimeout(() => {
        let emplazamientos = []
        this.iteracionSelected.emplazamientos.forEach((emplazamiento) => {
          if (emplazamiento.selected) {
            emplazamientos.push(emplazamiento)
          }
        })
        emplazamientos.forEach((emplazamiento) => {
          this.iteracionSelected.productos.forEach((producto) => {
            if (producto.emplazamiento.uuid === emplazamiento.uuid) {
              if (producto.selected) {
                producto.selected = false
              } else {
                producto.selected = true
              }
            }
          })
        })
        this.$forceUpdate()
        this.ui.loading = false
        //console.log(emplazamientos)
      }, 3000)
      //console.log('updateSelection', this.iteracionSelected.emplazamientos)
    },

    getTab() {
      this.$store.commit('expedientes/SET_TAB', tab)
    },
    async loadFormatosData(concesion) {
      this.$store.commit('formatos/SET_MODULO', 'expedientes')
      this.$store.commit('formatos/SET_MODULO_REF', this.item.uuid)
      this.$store.commit('formatos/SET_ALCANCE', this.iteracionSelected.alcance)

      // Formato Tipos
      this.$store.commit('formatos/GET_TIPOS', this.$auth.user.empresa.codigo)

      // Formato Data
      const body = {
        ...this.item,
        cliente: cliente,
      }

      // Cliente
      const cliente = await this.$store.dispatch('clientes/get', this.item.user.uuid)
      if (cliente) body.cliente = cliente

      // Solicitud
      const solicitud = await this.$store.dispatch(
        'grupo_solicitudes/get',
        this.iteracionSelected.grupo_solicitud.uuid
      )
      if (solicitud) body.solicitud = solicitud

      // Datos
      const datos = []
      datos.push(body)
      this.$store.commit('formatos/SET_DATOS', datos)

      // PDF
      // let route = '/formatos?plantilla='
      // route += concesion ? 'concesion' : 'informe'
      // this.$router.push(route)

      // New v2 formatos load request
      $nuxt.$emit('formatos:load', {
        subalcance: this.iteracionSelected.subalcance,
      })
    },
    updateSelection(selection) {
      this.action.selection = selection
    },
    async updateCodigo() {
      const body = {
        ...this.item,
      }
      await this.$store.dispatch(`expedientes/updateCodigo`, body)
    },

    async updateActivarDesactivar(activate) {
      if (activate) {
        await this.$store.dispatch('expedientes/activar', this.item.uuid)
        this.item.activo = true
        this.overlay = false
      } else {
        const res = await this.$store.dispatch('expedientes/desactivar', this.item.uuid)
        if (res) {
          // this.item.activo = false;
          // this.overlay = true;
          this.$router.push('/expedientes')
          this.$store.commit('notification/show', {
            text: 'Expediente desactivado correctamente',
            color: 'success',
          })
        }
      }
    },
    async saveAuditoria(auditoria) {
      await this.$store.dispatch(`expedientes/createAuditoria`, {
        iteracion_uuid: this.iteracionSelected.uuid,
        expediente_uuid: this.item.uuid,
        auditoria: auditoria,
      })
      this.dialogVisitaAuditoria = false
      this.$store.commit('notification/show', {
        text: 'Auditoria creada correctamente',
        color: 'success',
      })
      this.getOne()
    },
    formatDate(date) {
      return date ? this.$moment(date).format('DD-MM-YYYY') : ''
    },
    async executeRowAction(event) {
      if (event.selection && event.selection.length) {
        this.action = event
        this.dialog = true
      } else {
        this.noSelection = true
        setTimeout((this.noSelection = false), 10000)
      }
    },
    actionChanges(event) {
      console.log('actionChanges', event)
      const action = this.action.action
      const row = this.action.row
      const selection = this.action.selection
      const uuids = selection.map((element) => element.uuid)
      const field = this.action.field
      const tipo = this.action.tipo
      const newIteration = event.newIteration
      let newValue = event.newValue
      let changes = {
        table: [],
        changes: {},
      }
      let sites = this.iteracionSelected.emplazamientos
      for (let i = 0; i < selection.length; i++) {
        const element = selection[i]
        // Change value on grid
        if (action !== 'eliminar') {
          for (let k = 0; k < sites.length; k++) {
            const row = sites[k]
            if (row.uuid === element.uuid) {
              for (let n = 0; n < row.site_data.length; n++) {
                const keyname = row.site_data[n].keyname
                if (keyname === field) row.site_data[n].value = newValue
              }
            }
          }
        }
        this.iteracionSelected.sites = sites
        // Push changes
        changes.table.push({
          newIteration: newIteration,
          tipo: tipo,
          row: row[element.uuid],
          field: field,
          action: this.action.name,
          value: newValue,
        })
      }
      changes.changes = {
        newIteration: newIteration,
        tipo: tipo,
        iteracion: this.iteracionSelected.uuid,
        uuids: uuids,
        action: 'coplaca.' + tipo + '.' + action,
        value: newValue,
      }
      this.closeAction(changes)
    },
    closeAction(changes) {
      if (changes) {
        this.component++
        this.$store.commit('expedientes/updateChangesActions', changes.changes)
        this.$store.commit('expedientes/updateChangesTable', changes.table)
      }
      this.dialog = false
      this.action = {
        action: '',
        selection: [],
      }
      this.rowIndex = []
    },
    async saveChanges() {
      let payload = {
        uuid: this.item.uuid,
        codigo: this.item.codigo,
        estado: this.item.estado,
        numeroExpediente: this.item.numeroExpediente,
        numeroRegistro: this.item.numeroRegistro,
        descripcion: this.item.descripcion,
        user: this.item.user['@id'],
        activo: this.item.activo,
      }
      const res = await this.$store.dispatch('expedientes/update', payload)
      if (res) {
        this.$store.commit('notification/show', {
          text: 'Expediente guardado correctamente',
          color: 'success',
          setTimeout: 3000,
        })
        this.getOne()
      }
      /* if (this.changesTable.length > 0) {
        this.action = {
          action: 'save',
          selection: this.action.selection,
        }
        this.dialog = true
      } else {
        this.noChanges = true
        setTimeout(() => {
          this.noChanges = false
        }, 10000)
      } */
    },
    async saveAction() {
      this.dialog = false
      const res = await this.$store.dispatch(
        'expedientes/saveChanges',
        this.$auth.user.empresa.codigo
      )
      if (res && !res.error)
        this.$store.commit('notification/show', {
          text: 'Expediente guardado correctamente',
          color: 'success',
          setTimeout: 3000,
        })
      this.getOne()
    },
    iteracionMatchYear(i, year) {
      console.log('iteracionMatchYear', this.item.iteraciones[i].codigo, year)
      return this.$moment(this.item.iteraciones[i].codigo, 'DD/MM/YYYY').format('YYYY') === year
    },
    async getOne() {
      this.submenuItem = 0
      this.changesGrid = false
      this.$store.commit('expedientes/resetQueueChanges')
      const res = await this.$store.dispatch('expedientes/expediente', this.$route.params.uuid)
      if (res) {
        this.item = res
        const getSubalcance = await this.$store.dispatch(
          'subalcances/get',
          this.item.subalcance.uuid
        )
        if (getSubalcance) {
          this.item.subalcance = getSubalcance
          const getAlacance = await this.$store.dispatch(
            'alcances/get',
            this.item.subalcance.alcance.uuid
          )
          if (getAlacance) {
            this.item.alcance = getAlacance
          }
        }
        for (let i = 0; i < this.item.iteraciones.length; i++) {
          const getiteracciones = await this.$store.dispatch(
            'expedientes/iteracion',
            this.item.iteraciones[i].uuid
          )
          if (getiteracciones) {
            this.item.iteraciones[i] = getiteracciones
          }
        }

        // this.$store.commit('listas_validacion/SET_ALCANCE', this.item.alcance.uuid)
        if (this.item.activo === false) this.overlay = true
        let reverse = this.item.iteraciones.reverse()
        console.log('reverse', reverse)
        for (let i = 0; i < reverse.length; i++) {
          let date = this.$moment(reverse[i].codigo, 'DD/MM/YYYY').format('YYYY')
          if (!this.iteracionYears.includes(date)) this.iteracionYears.push(date)
        }
        this.iteracionYearSelected = this.$moment(reverse[0].codigo, 'DD/MM/YYYY').format('YYYY')
        this.item.iteraciones = reverse
        // Obtener Dropdowns y Tipos de Ficheros
        /* await this.$store.dispatch('dropdowns/list', {
          empresa: this.$auth.user.empresa.uuid,
          grupo_alcance: this.item.alcance.uuid,
        })
        let body = {
          grupo_alcance: this.item.grupo_alcance.uuid,
          empresa: this.$auth.user.empresa.uuid,
        }
        if (this.item.subalcance) body.subalcance = this.item.subalcance.uuid
        const tipos_ficheros = await this.$store.dispatch('tipos_fichero/list', body)
        if (tipos_ficheros) this.tipos_ficheros = tipos_ficheros */
        this.item.cliente = this.item.user

        await this.updateDatosIteracion(this.item.iteraciones[0])
      }
      this.component++
    },
    async updateDatosIteracion(iteracion) {
      const res = await this.$store.dispatch('expedientes/iteracion', [iteracion.uuid])
      if (res) {
        const data = res

        data.bloqueado_hasta = this.$moment(data.bloqueado_hasta).format('YYYY-MM-DD')
        data.elaboracion_4lote = this.$moment(data.elaboracion_4lote).format('YYYY-MM-DD')

        this.iteracionSelected = data

        let newsite = {}
        //data.productos.forEach((producto) => {
        //  newsite = producto
        //  //newsite.tipoEmplazamiento = 'actividad propia'
        //  this.iteracionSelected.emplazamientos.push(newsite)
        //})

        //this.loadFormatosData()
      }
    },

    //+-------------------------------------------------
    // updateFechaBloqueada()
    // Sets fecha_4lote and fecha_bloqueada_hasta
    // -----
    // Created on Tue Jul 19 2022
    //+-------------------------------------------------
    async updateFechaBloqueada(store = false) {
      if (this.isQuesoManchego && this.currentSolicitud?.tipo_solicitud == 'inicial') {
        if (!this.iteracionSelected.elaboracion_4lote) {
          this.iteracionSelected.elaboracion_4lote = this.$moment().format('YYYY-MM-DD')
        }

        this.iteracionSelected.bloqueado_hasta = this.$moment(
          this.iteracionSelected.elaboracion_4lote
        )
          .add(3, 'months')
          .format('YYYY-MM-DD')
      }

      if (store) this.updateFechasIteracion()
    },

    async updateFechasIteracion() {
      let body = {
        uuid: this.iteracionSelected.uuid,
        bloqueado_hasta: this.iteracionSelected.bloqueado_hasta,
        elaboracion_4lote: this.iteracionSelected.elaboracion_4lote,
      }
      await this.$store.dispatch(`expedientes/updateFechasIteracion`, body)
    },

    async checkRoles() {
      // this.auth.auditorias = await this.$store.dispatch('user/can', {
      //   module: 'auditorias',
      //   submodule: 'auditorias',
      //   return: 'all',
      // })
      this.auth.auditorias = await this.$store.dispatch('user/can', {
        module: 'auditorias',
      })

      this.auth.solicitudes = await this.$store.dispatch('user/can', {
        module: 'solicitudes',
        submodule: 'solicitudes',
      })

      this.auth.revisiones = await this.$store.dispatch('user/can', {
        module: 'revisiones',
        submodule: 'revisiones',
      })

      this.auth.dictamenes = await this.$store.dispatch('user/can', {
        module: 'dictamenes',
        submodule: 'dictamenes',
      })

      this.auth.certificados = await this.$store.dispatch('user/can', {
        module: 'certificados',
      })

      this.auth.expedientes = await this.$store.dispatch('user/can', {
        module: 'expedientes',
      })
    },

    goToLastDictamen() {
      this.$router.push(`/dictamenes/${this.item.lastDictamen.uuid}`)
    },

    goToLastCertificado() {
      this.$router.push(`/certificados/${this.item.lastCertificado.uuid}`)
    },

    goToLastAuditoria() {
      this.$router.push(`/auditorias/${this.item.lastAuditoria.uuid}`)
    },

    goToLastRevision() {
      this.$router.push(`/revisiones/${this.item.lastRevision.uuid}`)
    },
  },
  async mounted() {
    //this.$refs.dataTable.$el.addEventListener('mousedown', this.handleMouseDown);
    await this.getOne().then(() => {
      this.checkRoles().then(() => {
        this.tab = 'Productos'
        this.$nuxt.$emit('formatos:load', {
          subalcance: this.item.subalcance,
        })
      })
    })
  },
}
</script>

<style>
.toolbar-expediente-width-menu {
  background: #f5f5f5 !important;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.09) !important;
  z-index: 1;
  right: 0;
  top: 60px;
  left: 275px;
  position: fixed;
}

.ag-container {
  height: 100% !important;
  display: flex;
  flex-direction: column;
}

.toolbar-expediente-no-menu {
  background: #f5f5f5 !important;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.09) !important;
  color: #727891 !important;
  z-index: 1;
  right: 0;
  left: 0;
  top: 60px;
  position: fixed;
}

.info-expediente {
  height: 30px;
}

.info-expediente > .v-icon {
  color: #1839c0;
}

/* Estilo personalizado para la barra de desplazamiento horizontal */
.v-data-table > .v-data-table__wrapper > .v-data-table__overflow {
  overflow-x: auto;
}

.expandable-content {
  position: absolute;
  top: 100%; /* Adjust based on card height */
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1; /* Ensure it's on top of other elements */
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.expandable-content.show {
  opacity: 1;
}
</style>
